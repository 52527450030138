import React from 'react';
import Button from '@material-ui/core/Button';
import './BanUserModal.css'
import Draggable from 'react-draggable';
import Modal from 'react-modal';
import logo_window from './../../assets/window_icon.svg'
import LogoClose from '@material-ui/icons/Close'
import { truncateText } from '../../util/misc';

export default function BanUserModal({ username, open, banUser, banAndPurgeUser, handleClose }) {
    return (<Modal
        ariaHideApp={false}
        isOpen={open}
        style={{
            content: {
                width: "100%",
                height: "100%",
                paddingBottom: "0",
                filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#969392', endColorstr='#8a8685', GradientType=1) ",
                backgroundColor: "transparent",
                position: "unset",
                border: "none",
                borderRadius: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            overlay: {
                zIndex: "2",
                backgroundColor: "#000000ab",
            }
        }}
        contentLabel="Message Editor"
    >
        <Draggable bounds="parent" cancel='.ban-button, .purge-button, .logo_close'>
            <div className="commment_window_pop_up" >
                <div>
                    <div className="comment_window_pop_up_title">
                        <img className="logo_window" src={logo_window} alt="logo" />
                        <p className='title_comment_pop_up' >Confirm ban user to {truncateText(username, 30)}</p>
                        <div className='logo_close_div' onClick={handleClose}>
                            <LogoClose className='logo_close' />
                        </div>
                    </div>
                    <div className="post_dashboard_main_modal">
                        If you also want to delete their content click: Ban user and purge activity.
                        <br />
                        <br />
                        This operation can't be undone.
                        <div className="custom-modal-dialog">
                            <Button className='ban-button' variant="contained" onClick={banUser}>Ban User</Button>
                            <Button className='purge-button' variant="contained" onClick={banAndPurgeUser}>Ban User and purge activity</Button>
                        </div>
                    </div>
                </div >

            </div>
        </Draggable>
    </Modal>);
}
