// UserCommentsPanel.js
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import FlagIcon from '@material-ui/icons/Flag';
import Tooltip from "../Shared/ToolTip"
import ReactMarkdown from "react-markdown";
import DeleteIcon from '@material-ui/icons/DeleteForever';
import UsePagination from '../PostList/Paginator';
import internal_fetch from "../../util/local-api";
import Spinner from "../Shared/Spinner";
import logo_window from './../../assets/window_icon.svg'
import ArrowDropUpIcon from '@material-ui/icons/ThumbUp';
import ToolTip from "../Shared/ToolTip";
import ReactTimeAgo from 'react-time-ago';
import { truncateText } from '../../util/misc';
import { useAlert } from 'react-alert'

const UserCommentsPanel = ({ username, isAdmin, flagsOnly, numberDisplayed }) => {
  const alert = useAlert()

  const [userComments, updateUserComments] = useState([]);
  const [totalPages, updateTotalPages] = useState(0);
  const [selectedPage, updateSelectedPage] = useState(1);
  const [filteredComments, updateFilteredComments] = useState([]);
  const [isFetching, updateIsFetching] = useState(true);

  useEffect(() => {
    (async function iife() {
      //checks if user login check has done or not.
      if (isAdmin === undefined) return

      updateIsFetching(true);
      const { userByName } = await internal_fetch.user_posts_by_name(username, isAdmin);
      if (userByName) {
        updateUserComments(userByName.comments);
      }
      updateIsFetching(false);
    })();
  }, [username, isAdmin]);


  const deleteComment = async (commentId) => {
    if (window.confirm("Are you sure you want to delete?")) {
      const response = await internal_fetch.delete_comment(commentId);
      if (response.deleteComment) {
        alert.info(response.deleteComment);
        const [...tmpComments] = userComments.filter(comment => comment.id !== commentId);
        updateUserComments(tmpComments);
        //remove in state
      }
      else if (response.errors)
        alert.error(response.errors)
    }
  }

  useEffect(() => {
    const result = userComments.filter((p => flagsOnly ? (flagsOnly && p.flags && p.flags.length > 0) : true));
    updateFilteredComments(result)
    updateTotalPages(Math.ceil(result.length / numberDisplayed))
  }, [userComments, flagsOnly]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isFetching) return <Spinner loading={isFetching} />;

  if (!isFetching && filteredComments.length === 0) return <h1 className="nice_h1">No {username} comments found</h1>;

  return (
    <div className='user_panel'>
      <div className="comment_window_pop_up_title">
        <img className="logo_window" src={logo_window} alt="Logo Window" />
        <p className='title_comment_pop_up'>Comments by {truncateText(username, 30)}</p>
      </div>
      <div className='user_public_body'>
      {totalPages === 0 || totalPages === 1 || filteredComments.length <= 0 ? <></> : <UsePagination pages={totalPages} selectedPage={selectedPage} functions={{ updateSelectedPage }} ></UsePagination>}

      {filteredComments.slice((selectedPage - 1) * numberDisplayed, selectedPage * numberDisplayed).map((comment, i) => (
        <Link
          className="comment"
          to={`/post/${comment.post_id}`}
          key={comment.id}
        >
          <div className="upvote_box">
            <div className="upvote_display" >
              <ToolTip
                div={<div className="upvote_display_text">
                  {comment.upvotes}
                </div>}
                Icon={ArrowDropUpIcon}
                text={"Disabled"}
                size={20}
                color={"grey"}
              />
            </div>
          </div>
          <div className="comment_box">

            <div>
              <span style={{ paddingBottom: "5px" }}>
                <b>
                  <ReactTimeAgo date={+comment.creation_date} />
                </b>
              </span>
            </div>


            <hr style={{ backgroundColor: "#FFFFFF1e", height: "4px", borderColor: "transparent", borderRadius: "2px", width: "100%" }} />

            <span>
              <ReactMarkdown linkTarget="_blank" source={comment.text} />
            </span>
          </div>
          {comment.flags && comment.flags.length > 0 ? <div className="comment_favourite_flags">
            <Tooltip
              title=""
              Icon={FlagIcon}
              type="secondary"
              text={comment.flags.map(a => a.username).join()}
            />
          </div> : null}
          {
            isAdmin ? <div className="user_public_delete">
              <Tooltip Icon={DeleteIcon} text="Delete" secondary={true} onClick={(e) => { e.preventDefault(); deleteComment(comment.id) }} />
            </div> : null
          }
        </Link>
      ))}

      {totalPages === 0 || totalPages === 1 || filteredComments.length <= 0 ? <></> : <UsePagination pages={totalPages} selectedPage={selectedPage} functions={{ updateSelectedPage }} ></UsePagination>}

      </div>
    </div>
  )
};

export default UserCommentsPanel;

