import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";

import Spinner from "../Shared/Spinner";
import internal_fetch from "../../util/local-api";
import MessageModal from "../Inbox/MessageModal";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { useAlert } from 'react-alert'
import "react-tabs/style/react-tabs.css";
import "./UserPublic.css";
import UserPostsPanel from "./UserPostsPanel";
import UserCommentsPanel from "./UserCommentsPanel";
import UserFavoritePostsPanel from "./UserFavoritePostsPanel";
import SideNav from "../Nav/SideNav";
import { mobileRes } from "./../Nav/SideNav";
import UserPublicSideNavContent from "./UserPublicSideNavContent";

const LOAD_INCREMENT = 20;

const UserPublic = ({ windowState, user, updateUser, isLoggedIn, isAdmin, functions: { updateLeftBar } }) => {
  const alert = useAlert()

  const localIndex = JSON.parse(sessionStorage.getItem("user_public_tab_index"));

  const [userId, updateUserId] = useState([]);
  const [privateMessagesEnabled, updatePrivateMessagesEnabled] = useState(false);
  const [isFetching, updateIsFetching] = useState(true);
  const [currentTabIndex, setTabIndex] = useState(localIndex ? localIndex : 0);
  const [numberDisplayed, updateNumberDisplayed] = useState(LOAD_INCREMENT);
  const [profileExists, updateProfileExists] = useState(false);
  const [showMessageModal, updateModalShown] = useState(false);
  const [flagsOnly, updateFlagsOnly] = useState(false);

  let { username } = useParams();

  //On Mount
  useEffect(() => { updateLeftBar(true) }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //On Unmount
  useEffect(() => () => updateLeftBar(false), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => { updateLeftBar(windowState.windowWidth >= mobileRes) }, [windowState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async function iife() {
      //checks if user login check has done or not.
      if (isAdmin === undefined) return

      updateIsFetching(true);
      const { userByName } = await internal_fetch.user_posts_by_name(username, isAdmin);
      if (userByName) {
        updateUserId(userByName.id)
        updatePrivateMessagesEnabled(userByName.privateMessagesEnabled);
        updateProfileExists(true);
      }
      else {
        updateLeftBar(false)
      }
      updateIsFetching(false);
    })();
  }, [username, isAdmin]); // eslint-disable-line react-hooks/exhaustive-deps

  const blockUser = async () => {
    const response = await internal_fetch.update_block_user(userId, !userIsBlocked)
    if (response.updateBlockUserValue) {
      alert.info(response.updateBlockUserValue);
      window.location.reload(false);
    }
    else if (response.errors)
      alert.error(response.errors)
  }

  const purgeUser = async (userId) => {
    if (window.confirm("Are you sure you want to purge everything this user has done?")) {
      const response = await internal_fetch.purge_user(userId);
      if (response.purgeUser) {
        alert.info(response.purgeUser);
        // updateUserPosts([]);
        // updateUserComments([]);
      }
      else if (response.errors)
        alert.error(response.errors)
    }
  }

  useEffect(() => {
    sessionStorage.setItem("user_public_tab_index", JSON.stringify(currentTabIndex))
  }, [currentTabIndex])

  const userIsBlocked = user.data && user.data.blocked_users && user.data.blocked_users.findIndex(u => u.id === userId) !== -1;

  return (
    <div className="">
      {profileExists || isFetching ?
        <>
          <div>
            <div className="leaderboard_screen">
              <div className="leaderboard_controls">
                <SideNav user={user} updateUser={updateUser}>
                  <UserPublicSideNavContent
                    user={user}
                    userId={userId}
                    username={username}
                    isLoggedIn={isLoggedIn}
                    isAdmin={isAdmin}
                    currentTabIndex={currentTabIndex}
                    isFetching={isFetching}
                    privateMessagesEnabled={privateMessagesEnabled}
                    userIsBlocked={userIsBlocked}
                    updateModalShown={updateModalShown}
                    purgeUser={purgeUser}
                    blockUser={blockUser}
                    updateFlagsOnly={updateFlagsOnly}
                    sendMessage={() => { console.log("message") }}
                    seePostsBy={() => { setTabIndex(0) }}
                    seeCommentsBy={() => { setTabIndex(1) }}
                    seeFavorites={() => { setTabIndex(2) }}>
                    <div className="control_flags">  {isAdmin && currentTabIndex !== 2 ? <> <FormControlLabel label="Flags Only" control={<Checkbox checked={flagsOnly} onChange={(e) => { updateFlagsOnly(e.target.checked) }} value="flagsOnly" />} /></> : null}</div>
                  </UserPublicSideNavContent>
                </SideNav>
              </div>
              {isFetching ? <Spinner loading={isFetching} /> : <div className="user_public_main_body">
                <div style={{ width: "100%", padding: "10px" }}>

                  <Tabs selectedIndex={currentTabIndex} onSelect={(tabIndex) => { setTabIndex(tabIndex); if (currentTabIndex !== tabIndex) updateNumberDisplayed(LOAD_INCREMENT) }}>
                    <div className="tablist_user_public">
                      <TabList>
                        <Tab>Posts</Tab>
                        <Tab>Comments</Tab>
                        <Tab>Favourite Posts</Tab>
                    </TabList>
                    </div>
                    <TabPanel>
                      <UserPostsPanel
                        username={username}
                        isAdmin={isAdmin}
                        flagsOnly={flagsOnly}
                        numberDisplayed={numberDisplayed}
                      />
                    </TabPanel>
                    <TabPanel>
                      <UserCommentsPanel
                        username={username}
                        isAdmin={isAdmin}
                        flagsOnly={flagsOnly}
                        numberDisplayed={numberDisplayed}
                      />
                    </TabPanel>
                    <TabPanel>
                      <UserFavoritePostsPanel
                        username={username}
                        isAdmin={isAdmin}
                        numberDisplayed={numberDisplayed}
                      />
                    </TabPanel>
                  </Tabs>
                </div>

              </div>}
            </div >
          </div>
        </> : <h1 className="nice_h1">User not found</h1>
      }
      <MessageModal windowState={windowState} message={{ recipient: userId }} username={username} triggerUpdate={() => { }} updateModal={updateModalShown} modalShown={showMessageModal} />

    </div >
  );
};

export default UserPublic;
