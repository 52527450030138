import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { pageQuery } from "../../util/misc";
import SideNav from "../Nav/SideNav";
import { mobileRes } from '../Nav/SideNav';
import SearchIcon from '@material-ui/icons/Search';
import MobileControlDrawer from "./MobileControlDrawer"
import internal_fetch from "../../util/local-api";
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Select from 'react-select';
import "./PostControls.css";
const { parseQuery, defaults, constructQueryURL, sorts, dates } = pageQuery;

const colourStyles = {
    control: styles => ({
        ...styles, backgroundColor: '#3f3e3e5c', minHeight: "0px", padding: "0px", width: "250px", color: "white", boxShadow: "6px 6px 4px -1px rgba(0, 0, 0, 0.4)"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: "#3f3e3e5c",
            color: "white",
            cursor: isDisabled ? 'not-allowed' : 'default',

        };
    },
    dropdownIndicator: (styles) => {
        return {
            ...styles,
            padding: "0px",
            paddingRight: "5px"
        }
    },
    menu: (styles) => {
        return {
            ...styles,
            width: "250px",
            backgroundColor: "#3f3e3e",
            boxShadow: "6px 6px 4px -1px rgba(0, 0, 0, 0.4)"
        }
    },
    multiValue: (styles, { data }) => {
        return {
            ...styles,
            color: "white",
            borderRadius: "15px",
            padding: "3px 10px 3px 10px",
            backgroundColor: "#e67e22",
        };
    },
    singleValue: (styles, { data }) => {
        return {
            ...styles,
            color: "white",
        };
    },
    placeholder: (styles, { data }) => {
        return {
            ...styles,
            color: "white",
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: "white",
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        ':hover': {
            color: 'white',
        },
    }),
};

const PostControls = ({ windowState, user, isAdmin, updateUser, isFetching, selectedPage, functions: { updatePosts, updateIsFetching, updateTotalPages, appendPosts } }) => {


    const history = useHistory();
    const [query, setQuery] = useState(parseQuery())
    const [allCategories, setCategories] = useState([]);
    const [allStatuses, setStatuses] = useState([]);
    const [allVersions, setVersions] = useState([]);
    // const [page, updatePage] = useState(0);
    const [tempSearch, updateSearch] = useState("");
    const [previousQuery, updatePreviousQuery] = useState("");
    const [mobileDrawerExpanded, updateMobileDrawerExpanded] = useState(false);
    const [fetchOptions, updateFetchOptions] = useState({})
    const [isRequesting, updateIsRequesting] = useState(false);
    const [totalPosts, _updateTotalPosts] = useState(0);
    const [totalUpvotes, _updateTotalUpvotes] = useState(0);
    const [totalComments, _updateTotalComments] = useState(0);

    const mainRef = useRef(null);
    const textareaRef = useRef(null);

    const interactions = [{ text: "All Interactions", value: "all" },
    { text: "Unanswered", value: "0" },
    { text: "Answered", value: "1" }
    ]


    const updateInnerValue = (name) => (value) => {
        let { ...tmp } = fetchOptions;
        if (name === "sort" && value === 2) {
            tmp = { ...defaults };
            tmp.sort = 2;
        }
        else tmp[name] = value;
        if (name === "search" && value && tmp.date === defaults.date) {
            tmp.date = dates[5]
        }
        localStorage.setItem("fetch_options", JSON.stringify(tmp));

        updateFetchOptions(tmp);
        // updatePage(0)
    }

    const updateUrl = () => {
        const url = constructQueryURL(fetchOptions)
        //???????????????????
        if (previousQuery !== url) {
            history.push(url)
            updatePreviousQuery(url);
        }
    }

    const fetchPosts = async (page, cb) => {
        if (isFetching || !fetchOptions.date) return
        updateIsFetching(true);

        updateIsRequesting(true);

        const data = await internal_fetch.all_posts(fetchOptions.date.value, fetchOptions.search, fetchOptions.category, sorts[fetchOptions.sort].sort, fetchOptions.status, fetchOptions.versions, fetchOptions.answered, fetchOptions.showHidden, page * fetchOptions.limit, fetchOptions.limit);
        if (data && data.allPosts) {
            console.log(data.allPosts)
            cb(data.allPosts.posts);

            if (data && data.allPosts?.totalDocs) {
                _updateTotalPosts(data.allPosts.totalDocs)
            }
            else {
                _updateTotalPosts(0)
            }

            if (data && data.allPosts?.totalUpvotes) {
                _updateTotalUpvotes(data.allPosts.totalUpvotes)
            }

            if (data && data.allPosts?.totalComments) {
                _updateTotalComments(data.allPosts.totalComments)
            }

            updateTotalPages(data.allPosts.totalPages)
        }
        else {
            _updateTotalPosts(0)
            cb([])
        }

        updateIsRequesting(false);
        updateIsFetching(false);
    }

    useEffect(() => {
        if (!isRequesting) {
            fetchPosts(selectedPage - 1, appendPosts)
        }
    }, [selectedPage]); // eslint-disable-line react-hooks/exhaustive-deps

    history.listen((a, b, c) => {
        const newQuery = parseQuery();
        if (JSON.stringify(query) !== JSON.stringify(newQuery)) {
            setQuery(newQuery)
        }
    });

    useEffect(() => {
        if (windowState.windowWidth >= mobileRes) {
            updateMobileDrawerExpanded(false)
        }
    }, [windowState])

    useEffect(() => {
        let search = defaults.search;
        if (Object.entries(query).length > 0) {

            query.showHidden = (query.showHidden === "true" || query.showHidden === true)

            updateFetchOptions({
                sort: query.sort != null ? query.sort : defaults.sort,
                limit: query.limit != null ? query.limit : defaults.limit,
                search: query.search != null ? query.search : defaults.search,
                category: query.category != null ? query.category : defaults.category,
                date: query.date != null ? query.date : defaults.date,
                status: query.status != null ? query.status : defaults.status,
                versions: query.versions != null ? query.versions : defaults.version_number,
                answered: query.answered != null ? query.answered : defaults.answered,
                showHidden: query.showHidden != null ? query.showHidden : defaults.showHidden
            })
            search = query.search ? query.search : defaults.search;
        }
        else if (localStorage.getItem("fetch_options")) {
            const options = JSON.parse(localStorage.getItem("fetch_options"));
            search = options.search ? options.search : defaults.search;

            updateFetchOptions(options);
        }
        else {
            updateFetchOptions({
                sort: query.sort != null ? query.sort : defaults.sort,
                limit: query.limit != null ? query.limit : defaults.limit,
                search: query.search != null ? query.search : defaults.search,
                category: query.category != null ? query.category : defaults.category,
                date: query.date != null ? query.date : defaults.date,
                status: query.status != null ? query.status : defaults.status,
                versions: query.versions != null ? query.versions : defaults.version_number,
                answered: query.answered != null ? query.answered : defaults.answered,
                showHidden: query.showHidden != null ? query.showHidden : defaults.showHidden
            })
            search = query.search ? query.search : defaults.search;
        }
        updateSearch(search);
    }, [JSON.stringify(query)]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        (async function iife() {
            const [cats, versions, stats] = await Promise.all([internal_fetch.get_categories(), internal_fetch.get_versions(), internal_fetch.get_statuses()])
            if (cats.categories)
                setCategories(cats.categories);
            if (versions.versions)
                setVersions(versions.versions);
            if (stats.statuses)
                setStatuses(stats.statuses.map(x => Object({ key: x.split(":")[0], value: x.split(":")[1] })));
        })();
    }, [])

    useEffect(() => {
        fetchPosts(0, updatePosts);
        updateUrl();
    }, [JSON.stringify(fetchOptions)]); // eslint-disable-line react-hooks/exhaustive-deps

    const isOnHot = false;// fetchOptions.sort === 2;

    useEffect(() => {
        var element = document.getElementById('auto-grow-textarea');

        if (!element) {
            element = textareaRef.current
            if (!element) return
        }

        if (`${tempSearch}`.length < 20) {
            console.log("tempSearch 32")
            element.style.height = '32px';
        }
        else {
            console.log("tempSearch auto")

            element.style.height = 'auto';
            element.style.height = (element.scrollHeight) + 'px'; // 10px for padding
        }
    }, [tempSearch]);

    const handleChange = (event) => {
        updateSearch(event.target.value);
    };

    if (Object.entries(fetchOptions).length < 1)
        return null;
    return (<div className="post_controls">

        <div className="post_controls_upper" style={mobileDrawerExpanded ? isOnHot ? { height: "45px" } : { height: "190px" } : windowState.windowWidth < 884 ? { height: "20px" } : {}}>
            <MobileControlDrawer expanded={mobileDrawerExpanded} toggle={() => updateMobileDrawerExpanded(!mobileDrawerExpanded)}>
                <div className="post_list_filters">
                    <form>
                        {
                            sorts.map((s, i) => <label key={i}><input disabled={isFetching} type="radio" checked={fetchOptions.sort === i} onChange={() => updateInnerValue("sort")(i)} />{s.text}</label>)
                        }
                    </form>
                </div>
                {isOnHot ? null :
                    <div className="mobile_drawer_container" >
                        <h1 style={{ color: "#e69344", fontWeight: "600", margin: "5px" }}>Search Filters:</h1>
                        <select disabled={isFetching || isOnHot} onChange={(e) => updateInnerValue("category")(e.target.value)}>
                            <option value="">All Categories</option>
                            {allCategories.map((category, i) => <option selected={category === fetchOptions.category} value={category} key={i}>{category}</option>)}
                        </select>

                        <select disabled={isFetching || isOnHot} onChange={(e) => updateInnerValue("versions")(e.target.value)}>
                            <option value="">All Versions</option>
                            {allVersions.map((version, i) => <option selected={version === fetchOptions.versions} value={version} key={i}>{version}</option>)}
                        </select>

                        <div className="mobile_drawer_divider">
                            <div>
                                <select disabled={isFetching || isOnHot} onChange={(e) => updateInnerValue("date")(dates.find(d => d.value === +e.target.value))}>
                                    {dates.map((d, i) => <option key={i} selected={fetchOptions.date.value === d.value} value={d.value}>{d.text}</option>)}
                                </select>
                                <select disabled={isFetching || isOnHot} onChange={(e) => updateInnerValue("answered")(e.target.value)}>
                                    <option value="all" selected={fetchOptions.answered === "all"}>All Interactions</option>
                                    <option value="0" selected={fetchOptions.answered === "0"} >Unanswered</option>
                                    <option value="1" selected={fetchOptions.answered === "1"} >Answered</option>
                                </select>

                            </div>
                            <div>
                                <select disabled={isFetching || isOnHot} onChange={(e) => updateInnerValue("status")(e.target.value)}>
                                    <option value="">All Statuses</option>
                                    {allStatuses.map((status, i) => <option selected={status.key === fetchOptions.status} value={status.key} key={i}>{status.value}</option>)}
                                </select>
                            </div>
                        </div>
                    </div>}

            </MobileControlDrawer>
            <>
                <SideNav user={user} updateUser={updateUser}>
                    <div className="post_controls_lower">
                        <div className="post_searchbar">
                            <textarea id="auto-grow-textarea" className="grow-input" ref={textareaRef} disabled={isFetching} maxLength={100} type="text" placeholder="Search..." value={tempSearch} onKeyDown={(e) => e.key === "Enter" ? updateInnerValue("search")(tempSearch) : null} onChange={(e) => handleChange(e)} />
                            <SearchIcon onClick={() => mainRef.current.focus()} style={{ fontSize: "24px" }} />
                        </div>
                    </div>


                    <Select
                        value={fetchOptions.sort ? [sorts[fetchOptions.sort]].map((e) => { return { label: e.text, value: fetchOptions.sort } }) : { label: "Top", value: 0 }}
                        options={sorts.map((s) => { return { label: s.text, value: sorts.findIndex((a) => { return a.text === s.text }) } })}
                        className="basic-multi-select"
                        styles={colourStyles}
                        onChange={(e) =>
                            updateInnerValue("sort")(e.value)
                        }
                    />

                    <Select
                        value={fetchOptions.category ? fetchOptions.category.split(",").map((category) => { return { label: category, value: category } }) : []}
                        isMulti
                        options={allCategories.map((category) => { return { label: category, value: category } })}
                        className="basic-multi-select"
                        placeholder="All Categories"
                        styles={colourStyles}
                        onChange={(options) => {
                            if (options && options.length > 0) {
                                const selectedCategories = options.map(p => p.value).join(",")
                                updateInnerValue("category")(selectedCategories)
                            }
                            else
                                updateInnerValue("category")("")

                        }}
                    />
                    <Select
                        value={fetchOptions.versions ? fetchOptions.versions.split(",").map((version) => { return { label: version, value: version } }) : []}
                        isMulti
                        options={allVersions.map((version) => { return { label: version, value: version } })}
                        className="basic-multi-select"
                        placeholder="All Versions"
                        styles={colourStyles}
                        onChange={(options) => {
                            if (options && options.length > 0) {
                                const selectedVersions = options.map(p => p.value).join(",")
                                updateInnerValue("versions")(selectedVersions)
                            }
                            else
                                updateInnerValue("versions")("")

                        }}
                    />
                    <Select
                        value={fetchOptions.date ? { label: fetchOptions.date.text, value: fetchOptions.date.version } : []}
                        options={dates.map((date) => { return { label: date.text, value: date.value } })}
                        className="basic-multi-select"
                        styles={colourStyles}
                        onChange={(e) =>
                            updateInnerValue("date")(dates.find(d => d.value === +e.value))
                        }
                    />
                    <Select
                        value={interactions.filter(i => i.value === fetchOptions.answered).map((e) => { return { label: e.text, value: e.value } })}
                        options={interactions.map((interaction) => { return { label: interaction.text, value: interaction.value } })}
                        className="basic-multi-select"
                        styles={colourStyles}
                        onChange={(e) =>
                            updateInnerValue("answered")(e.value)
                        }
                    />
                    <Select
                        value={fetchOptions.status ? allStatuses.filter(s => s.key === fetchOptions.status).map((e) => { return { label: e.value, value: e.key } }) : { label: "All Statuses", value: "" }}
                        options={[{ value: "All Statuses", key: "" }].concat(allStatuses).map((status) => { return { label: status.value, value: status.key } })}
                        className="basic-multi-select"
                        styles={colourStyles}
                        onChange={(e) =>
                            updateInnerValue("status")(e.value)
                        }
                    />

                    <FormControlLabel
                        control={
                            <Checkbox checked={fetchOptions.showHidden} onChange={(e) => {
                                updateInnerValue("showHidden")(e.target.checked)
                            }} value={fetchOptions.showHidden} disabled={false} />
                        }
                        label="Show Hidden"
                    />

                    {
                        totalPosts > 0 && isAdmin && !isFetching ? <div style={{ opacity: "0.7", marginLeft: "18px", marginBottom: "18px", flexGrow: "2", display: "flex", alignItems: "end", }}>
                            Search result summary
                            <br />
                            <br />
                            Total Posts: {totalPosts}
                            <br /><br />
                            Total Votes: {totalUpvotes}
                            <br /><br />
                            Total Comments: {totalComments}
                        </div> : <></>
                    }
                </SideNav>
            </>
        </div>
        {
            isOnHot ? null :
                <div className="post_controls_lower">
                    <div className="post_searchbar">
                        <input ref={mainRef} disabled={isFetching} maxLength={100} type="text" placeholder="Search..." value={tempSearch} onKeyDown={(e) => e.key === "Enter" ? updateInnerValue("search")(tempSearch) : null} onChange={(e) => updateSearch(e.target.value)} />
                        <SearchIcon onClick={() => mainRef.current.focus()} style={{ fontSize: "24px" }} />
                    </div>
                </div>
        }
    </div>);
}

export default PostControls;

export const mobileResValue = mobileRes;
