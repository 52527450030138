import React, { useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { NavLink as Link } from "react-router-dom";
import { isAdmin, isLoggedLocal, setUserNameLocal, userNameLocal } from '../../util/misc';

const darkTheme = createMuiTheme({
    palette: {
        type: 'dark',
    },
});

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
}));

export default function MenuListComposition({ user, logout, }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false && anchorRef.current) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        if (user?.isLoggedIn) {
            setUserNameLocal(user.data?.username)
        }
    }, [user]);

    return (
        <ThemeProvider theme={darkTheme}>
        <div className={classes.root}>
            <div>

                {user?.isLoggedIn || isLoggedLocal() ? (
                    <Button
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}>
                        {userNameLocal()} {user?.data?.unreadMessages > 0 ? <div className="unread_messages"> ({user.data?.unreadMessages}) </div > : null}
                    </Button>
                ) : (
                        <Link
                            to="/login" activeClassName="nav_active" className="nav_block" style={{ color: "white" }}
                        >
                            <Button >Login</Button>
                        </Link>
                    )}
                {user?.isLoggedIn ? <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 5000 }}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', }}
                        >
                            <Paper >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <Link to="/profile">  <MenuItem>Profile</MenuItem></Link>
                                        <Link to={`/user/${user.data.username}`}>  <MenuItem>Public Page</MenuItem></Link>
                                        <Link to="/inbox">  <MenuItem>Inbox</MenuItem></Link>
                                        {user?.isLoggedIn && isAdmin(user.data.role)
                                            ? (
                                                [
                                                    <Link to="/admin_panel" key="admin_panel"> <MenuItem>Admin</MenuItem></Link>,
                                                    <Link to="/assigned_posts" key={"assigned_posts"}> <MenuItem>Assigned</MenuItem></Link>
                                                ]
                                            ) : null}

                                        <Link to="/inbox">
                                        <MenuItem
                                            onClick={() => {
                                                logout();
                                                handleToggle();
                                            }}
                                        >
                                            Logout
                                        </MenuItem>
                                        </Link>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper> : null}
            </div>
            </div>
        </ThemeProvider>
    );
}
