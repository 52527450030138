import React, { useState, useEffect } from 'react';
import internal_fetch from "../../util/local-api"
import SideNav from '../Nav/SideNav';
import { mobileRes } from "./../Nav/SideNav";
import LeaderboardSideNavContent from './LeaderboardSideNavControls';
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import UserPostsPanel from '../UserPublic/UserPostsPanel';
import UserCommentsPanel from '../UserPublic/UserCommentsPanel';
import UserFavoritePostsPanel from '../UserPublic/UserFavoritePostsPanel';
import LeaderboardBody from './LeaderboardBody';

const LOAD_INCREMENT = 20;

const Leaderboard = ({ windowState, user, updateUser, isAdmin, functions: { updateLeftBar } }) => {
    const localIndex = JSON.parse(sessionStorage.getItem("leaderboard_tab_index"));

    const [data, updateData] = useState([]);
    const [highlightName, updateHighlightName] = useState("");
    const [page, updatePage] = useState(0);
    const [isFetching, updateIsFetching] = useState(false)
    const [totalPages, updateTotalPages] = useState(0);
    const [selectedPage, updateSelectedPage] = useState(0);
    const [currentTabIndex, setTabIndex] = useState(localIndex ? localIndex : 0);
    const [numberDisplayed, updateNumberDisplayed] = useState(LOAD_INCREMENT);
    const [foundUser, updateFoundUser] = useState(false)

    //On Mount
    useEffect(() => { updateLeftBar(true) }, []); // eslint-disable-line react-hooks/exhaustive-deps
    //On Unmount
    useEffect(() => () => updateLeftBar(false), []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { updateLeftBar(windowState.windowWidth >= mobileRes) }, [windowState]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchUsers = async (username) => {
        updateIsFetching(true);
        const response = await internal_fetch.leaderboard_snap(highlightName, page);
        if (response && response.leaderboardSnap) {
            updateData(response.leaderboardSnap.users)
            updateTotalPages(response.leaderboardSnap.totalPages)
            updateIsFetching(false);
            updateHighlightName(highlightName.toLowerCase());
            if (highlightName && response.leaderboardSnap.users?.length > 0) {
                updateFoundUser(true)
            }
            else {
                updateFoundUser(false)
            }
        }
    }

    useEffect(() => {
        if (selectedPage !== page + 1)
            updatePage(selectedPage - 1)
    }, [selectedPage]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!data.some(user => user.username.toLowerCase() === highlightName)) {
            fetchUsers();
            if (!highlightName) {
                setTabIndex(0)
            }
        }
        else {
            updateFoundUser(true)
        }
    }, [page, highlightName]); // eslint-disable-line react-hooks/exhaustive-deps


    return (<div className="">
        <div className="leaderboard_screen">
            <div className="leaderboard_controls">
                <SideNav user={user} updateUser={updateUser}>
                    <LeaderboardSideNavContent isFetching={isFetching} page={page} foundUser={foundUser} data={data} currentTabIndex={currentTabIndex} setTabIndex={setTabIndex} updateHighlightName={updateHighlightName} />
                </SideNav>
            </div>
            <div className="user_public_main_body">
                <div style={{ width: "100%", padding: "10px" }}>
                    <Tabs selectedIndex={currentTabIndex} onSelect={(tabIndex) => { setTabIndex(tabIndex); if (currentTabIndex !== tabIndex) updateNumberDisplayed(LOAD_INCREMENT) }}>
                        <TabList style={{ display: "none" }}> {/* This is set only by requirement */}
                            <Tab>Tab 0</Tab>
                            <Tab>Tab 1</Tab>
                            <Tab>Tab 2</Tab>
                            <Tab>Tab 3</Tab>
                        </TabList>
                        <TabPanel>
                            <LeaderboardBody isFetching={isFetching} totalPages={totalPages} data={data} highlightName={highlightName} updateHighlightName={updateHighlightName} selectedPage={selectedPage} updateSelectedPage={updateSelectedPage} />
                        </TabPanel>
                        <TabPanel>
                            <UserPostsPanel username={highlightName} isAdmin={isAdmin} flagsOnly={false} numberDisplayed={numberDisplayed} />
                        </TabPanel>
                        <TabPanel>
                            <UserCommentsPanel username={highlightName} isAdmin={isAdmin} flagsOnly={false} numberDisplayed={numberDisplayed} />
                        </TabPanel>
                        <TabPanel>
                            <UserFavoritePostsPanel username={highlightName} isAdmin={isAdmin} numberDisplayed={numberDisplayed} />
                        </TabPanel>
                    </Tabs>
                </div>

            </div>
        </div >
    </div>);
}

export default Leaderboard;