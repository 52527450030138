import React, { useState, useMemo } from "react";
import { NavLink as Link } from "react-router-dom";
import UpvoteDisplay from "../Shared/UpvoteDisplay";
import ReactTimeAgo from "react-time-ago/commonjs/ReactTimeAgo";

import internal_fetch from "../../util/local-api";
import { isAdmin, isModerator } from "../../util/misc";
import FancyButton from "../Shared/FancyButton";
import FlagIcon from '@material-ui/icons/Flag';
import { useAlert } from 'react-alert'
import ToolTip from "../Shared/ToolTip";
import DeleteIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ReplyIcon from '@material-ui/icons/Reply';
import { Tooltip } from "@material-ui/core";
import { truncateText } from '../../util/misc';
import PostPageRichContent from "./PostPageRichContent";

const PostPageComment = ({ comment, isLoggedIn, userVoted, voteComment, user, triggerUpdate, postAuthorId, isSubComment, toggleParentReply, postClosed }) => {
  const [isFetching, updateIsFetching] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [showReply, toggleReply] = useState(false);
  const [commentText, updateCommentText] = useState(comment.text);
  const [replyText, updateReplyText] = useState();
  const [tmpText, updateTmpText] = useState(comment.text);
  const [previewMode, updatePreviewMode] = useState(false);
  const [editPreviewMode, updateEditPreviewMode] = useState(false);

  const canEdit = user.data && comment.author && user.data.id === comment.author.id;
  const canDelete = user.data && (isAdmin(user.data.role) || isModerator(user.data.role) || (comment.author && user.data.id === comment.author.id))
  const canMark = !isSubComment && user.data && ((postAuthorId === user.data.id) || (isAdmin(user.data.role) || isModerator(user.data.role)))
  const canFlag = user.data && comment.author && comment.author.id !== user.data.id;
  const hasFlaggedComment = useMemo(() => user.data && comment && comment.flags.findIndex(flag => flag.id === user.data.id) !== -1, [comment, user.data]);
  const alert = useAlert()



  const updateComment = async () => {
    updateIsFetching(true);
    const response = await internal_fetch.update_comment(comment.id, commentText);
    updateIsFetching(false);
    if (response.updateComment) {
      alert.info("Updated!")
      setEdit(false)
      updateTmpText(commentText);
    }
    else if (response.errors)
      alert.error(response.errors)
  }
  const deleteComment = async () => {
    const response = await internal_fetch.delete_comment(comment.id);
    if (response.deleteComment) {
      alert.info("Deleted!")
      triggerUpdate();
    }
    else if (response.errors)
      alert.error(response.errors)
  }

  const markComment = async (isAnswer) => {
    const response = await internal_fetch.mark_comment(comment.id, isAnswer);
    if (response.markComment) {
      alert.info("Updated");
      triggerUpdate();
    }
    else if (response.errors)
      alert.error(response.errors)
  }

  const replyToComment = async (isAnswer) => {
    const response = await internal_fetch.create_sub_comment(comment.id, replyText);
    if (response.createSubComment) {
      alert.info("Replied");

      triggerUpdate();
    }
    else if (response.errors)
      alert.error(response.errors)
  }

  const flagComment = (value) => async () => {
    const response = value ? await internal_fetch.flag_comment(comment.id) : await internal_fetch.unflag_comment(comment.id);;
    if (response.flagComment || response.unflagComment) {
      const { ...tmpComment } = comment;
      if (response.flagComment === "Flagged.") {
        tmpComment.flags.push({ id: user.data.id });
      }
      else if (response.unflagComment === "Unflagged.") {
        tmpComment.flags = tmpComment.flags.filter(flag => flag.id !== user.data.id);
      }
      triggerUpdate();
    }
  }
  return (
    <div className={comment.isAnswer ? "post_comment_answer" : "post_comment"} >
      {isSubComment ? null : <UpvoteDisplay

        disabled={!isLoggedIn || isFetching}
        upvotes={comment.upvotes}
        userVoted={userVoted}
        action={async () => {
          updateIsFetching(true);
          const response = await internal_fetch.vote_comment(
            comment.id,
            userVoted
          );
          if (userVoted) {
            comment.upvotes--
          }
          else {
            comment.upvotes++
          }
          updateIsFetching(false);
          if (response) voteComment(comment, userVoted);
        }}
      />}


      <div className="post_comment_body">
        <div className="post_comment_body_header">
          <div className="post_comment_author">
            {comment.author
              ? <Link to={`/user/${comment.author.username}`}> <p>{truncateText(comment.author.username, 30)}</p>  {isAdmin(comment.author.role)
                ? <Tooltip title="(Developer)"><img src="https://satisfactoryweb.blob.core.windows.net/public/u3_assets/CSS%20LOGO.png" className="dev_logo" alt="dev" /></Tooltip>
                : isModerator(comment.author.role) ? "(MOD)"
                  : ""
              } </Link>
              : "deleted"}
            {comment.author ? <div className="post_comment_points">
              ({comment.author.points} points) -  <ReactTimeAgo date={+comment.creation_date} />
            </div> : null}
          </div>
          <div className="post_comment_controls">
            {
              isLoggedIn ? <ToolTip Icon={ReplyIcon}
                onClick={postClosed ? null : isSubComment ? toggleParentReply : () => toggleReply(!showReply)}
                text={postClosed ? "Post Closed" : "Reply"}
                disabled={postClosed}
                color={postClosed ? "transparent" : null}
              /> : null
            }

            {canFlag ? isLoggedIn ? hasFlaggedComment ? <ToolTip Icon={FlagIcon} text="Unflag Comment" className="comment_flag" color="red" onClick={flagComment(false)} /> : <ToolTip Icon={FlagIcon} text="Flag Comment" className="comment_flag" onClick={flagComment(true)} /> : null : null} {

              canEdit ?
                <ToolTip Icon={EditIcon} text="Edit" onClick={() => setEdit(!isEdit)} /> : null
            }
            {canDelete ? <ToolTip Icon={DeleteIcon} text="Delete" secondary={true} onClick={() => window.confirm("Really delete comment?") ? deleteComment() : null} /> : null}
            {canMark ? comment.isAnswer ? <ToolTip Icon={CheckIcon} color="red" text="Unmark as Answer" onClick={() => markComment(false)} /> :
              <ToolTip Icon={CheckIcon} text="Mark as Answer" onClick={() => markComment(true)} /> : null}
          </div>
        </div>
        {comment.lastEdit !== 0 ? <div className="post_comment_edited">
          <div>Edited: <ReactTimeAgo timeStyle="twitter" date={+comment.lastEdit} /> ago</div>
        </div> : null}
        <hr />
        {!isEdit ? <div className={isSubComment ? "post_comment_text" : "post_comment_text parent"}>
          <PostPageRichContent post={{ comment: tmpText }} />
        </div> : <div className={editPreviewMode ? "post_comment_text_editor_preview" : "post_comment_text_editor"}>
          {!editPreviewMode ? <textarea className="comment_panel_descriptor_textarea" value={commentText} onChange={(e) => updateCommentText(e.target.value)} /> : <div className="post_comment_text"> <PostPageRichContent post={{ comment: commentText }} /> </div>}

            <div className="comment_buttons" style={{ float: "right", marginTop: "5px" }}>
              <FancyButton text="Cancel" onClick={() => setEdit(!isEdit)} style={{ marginRight: "5px" }} />
              <FancyButton text="Toggle Preview" onClick={() => updateEditPreviewMode(!editPreviewMode)} style={{ marginRight: "5px" }} />
              <FancyButton text="Save" disabled={isFetching} onClick={!isFetching ? updateComment : null} />
            </div>
          </div>}
        {comment.subComments ?
          <div className="sub_comments">
            {comment.subComments.map((subComment, i) =>
              <PostPageComment postClosed={postClosed} comment={subComment} key={i} isSubComment={true} isLoggedIn={isLoggedIn} subComment={true} user={user} triggerUpdate={triggerUpdate} postAuthorId={postAuthorId} toggleParentReply={() => toggleReply(!showReply)} />
            )}</div> : null
        }
        {
          showReply ? <div className="post_comment_text_editor">
            {!previewMode ? <textarea className="comment_panel_descriptor_textarea" placeholder="Reply..." value={replyText} onChange={(e) => updateReplyText(e.target.value)} /> : <div className="post_comment_text"><PostPageRichContent post={{ comment: replyText }} /></div>}
            <div className="comment_buttons" style={{ float: "right", marginTop: "5px" }}>
              <FancyButton text="Cancel" onClick={() => toggleReply(!showReply)} style={{ marginRight: "5px" }} />
              <FancyButton text="Toggle Preview" onClick={() => updatePreviewMode(!previewMode)} style={{ marginRight: "5px" }} />
              <FancyButton text="Reply" disabled={isFetching} onClick={!isFetching ? replyToComment : null} />
            </div>
          </div> : null
        }
      </div>
    </div >
  );
};

export default PostPageComment;
