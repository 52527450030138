import { createApolloFetch } from "apollo-fetch";
import config from "../config";
import { getIdToken, clearIdToken } from "../util/auth";

const api_url = "/graphql";

export default {
  banner: function () {
    return query(`
        query  {
          banner
        }` , "getBanner")
  },
  update_banner: function (content) {
    return query(`
      mutation ($content: String!){
        updateBanner(content: $content)
      }
    `, "updateBanner", { content }, true)
  },
  banner_new_post: function () {
    return query(`
        query  {
          bannerNewPost
        }` , "getBannerNewPost")
  },
  update_banner_new_post: function (content) {
    return query(`
      mutation ($content: String!){
        updateBannerNewPost(content: $content)
      }
    `, "updateBannerNewPost", { content }, true)
  },
  all_posts: function (date_range, search, category, sort, status, version_number, answered, showHidden, skip, limit) {
    return query(
      `query($date_range: Float, $search: String, $category: String, $sort: String, $status: String, $version_number: String, $answered: String!, $showHidden: Boolean! $skip: Int!, $limit: Int!) {
              allPosts(date_range: $date_range, search: $search, category: $category, sort: $sort, status: $status, version_number: $version_number, answered: $answered, showHidden: $showHidden, skip: $skip, limit: $limit) {
                posts{
                  id
                  title
                  upvotes
                  categories
                  contents
                  creation_date
                  status
                  version_number
                  author {
                      username
                      role
                  }
                  comments{
                      id
                  }
                  countComments
                  answered
                  isPinned
                  pinnedDate
                  admin_data{
                    in_progress
                  }
                  hidden
                }
                totalDocs
                totalUpvotes
                totalComments
                totalPages
                limit
                hasPrevPage
                hasNextPage
              }
          }
        `, "allPosts",
      { date_range, search, category, sort, status, version_number, answered, showHidden, skip, limit }, true
    )
  },
  all_patch_notes: function (date_range, search, category, sort, status, version_number, answered, showHidden, skip, limit) {
    return query(
      `query($date_range: Float, $search: String, $category: String, $sort: String, $status: String, $version_number: String, $answered: String!, $showHidden: Boolean! $skip: Int!, $limit: Int!) {
              allPatchNotes(date_range: $date_range, search: $search, category: $category, sort: $sort, status: $status, version_number: $version_number, answered: $answered, showHidden: $showHidden, skip: $skip, limit: $limit) {
                posts{
                  id
                  title
                  upvotes
                  categories
                  contents
                  creation_date
                  status
                  version_number
                  author {
                      username
                      role
                  }
                  comments{
                      id
                  }
                  isPinned
                  pinnedDate
                  countComments
                  answered
                  admin_data{
                    in_progress
                  }
                  hidden
                }
                totalDocs
                totalUpvotes
                totalComments
                totalPages
                limit
                hasPrevPage
                hasNextPage
              }
          }
        `, "allPatchNotes",
      { date_range, search, category, sort, status, version_number, answered, showHidden, skip, limit }, true
    )
  },
  latest_solved_posts: function (date_range, search, category, sort, version_number, skip, limit) {
    return query(
      `query($date_range: Float, $search: String, $category: String, $sort: String, $version_number: String, $skip: Int!, $limit: Int!) {
        latestSolvedPosts(date_range: $date_range, search: $search, category: $category, sort: $sort, version_number: $version_number, skip: $skip, limit: $limit) {
                posts{
                  id
                  title
                  categories
                  contents
                  creation_date
                  lastEdit
                  status
                  version_number
                  author {
                    username
                  }
                  comments{
                    id
                  }
                  countComments
                }
                totalDocs
                totalPages
                limit
                hasPrevPage
                hasNextPage
                hasNextPage
              }
          }
        `, "latestSolvedPosts",
      { date_range, search, category, sort, version_number, skip, limit }, true
    )
  },
  specific_post: function (id, isAdmin) {
    if (isAdmin) {
      return query(
        `query ($id: ID!) {
          post(id: $id) {
              id
              is_patch_note
              title
              contents
              upvotes
              categories
              creation_date
              version_number
              status
              answered
              likeGiven
              hidden
              countComments
              author {
                  username
                  points
                  role
                  id
              }
              comments{
                  id
                  text
                  author{
                      username
                      points
                      role
                      id
                  }
                  creation_date
                  upvotes
                  lastEdit
                  isAnswer
                  flags{
                    id
                  }
                  subComments {
                    id
                    text
                    author{
                      username
                      points
                      role
                      id
                    }
                    creation_date
                    upvotes
                    lastEdit
                    flags {
                      id
                    }
                  }
              }
              favourites{
                id
              }
              isPinned
              pinnedDate
              lastEdit
              duplicates{
                post_id
                post_title
                post_upvotes
                valuable
              }
              assignedTo{
                id
                username
              }
              flags{
                id
              }
              coordinates
              admin_data {
                notes
                in_progress
              }
          }
      }
    `, "getSpecificPostAdmin",
        { id }, true
      )
    }
    return query(
      `query ($id: ID!) {
              post(id: $id) {
                  id
                  is_patch_note
                  title
                  contents
                  upvotes
                  categories
                  creation_date
                  version_number
                  status
                  answered
                  likeGiven
                  countComments
                  author {
                      username
                      points
                      role
                      id
                  }
                  comments{
                      id
                      text
                      author{
                          username
                          points
                          role
                          id
                      }
                      creation_date
                      upvotes
                      lastEdit
                      isAnswer
                      flags{
                        id
                      }
                      subComments {
                        id
                        text
                        author{
                          username
                          points
                          role
                          id
                        }
                        creation_date
                        upvotes
                        lastEdit
                        flags {
                          id
                        }
                      }
                  }
                  favourites{
                    id
                  }
                  isPinned
                  pinnedDate
                  lastEdit
                  duplicates{
                    post_id
                    post_title
                    post_upvotes
                    valuable
                  }
                  assignedTo{
                    id
                    username
                  }
                  flags{
                    id
                  }
                 
                  coordinates
              }
          }
        `, "getSpecificPost",
      { id }, true
    )
  },
  specific_post_comments: function (id, skip, limit) {
    return query(
      `query ($id: ID!, $skip: Int!, $limit: Int!) {
        allPostComments(id: $id, skip: $skip, limit: $limit) {
            comments{
                id
                text
                author{
                    username
                    points
                    role
                    id
                }
                creation_date
                upvotes
                lastEdit
                isAnswer
                flags{
                  id
                }
                subComments {
                  id
                  text
                  author{
                    username
                    points
                    role
                    id
                  }
                  creation_date
                  upvotes
                  lastEdit
                  flags {
                    id
                  }
                }
            }
            totalDocs
            totalPages
            limit
            hasPrevPage
            hasNextPage
        }
    }
  `, "allPostComments",
      { id, skip, limit }, true
    )
  },
  create_post: function (title, contents, version_number, categories) {
    return query(
      `mutation ($title: String!, $contents: String!, $version_number: String!, $categories: [String!]!) {
            createPost(title: $title, contents: $contents, version_number: $version_number, categories: $categories) {
                id
            }
        }
        `, "createPost",
      { title, contents, version_number, categories },
      true
    );
  },
  create_post_admin: function (title, contents, version_number, categories) {
    return query(
      `mutation ($title: String!, $contents: String!, $version_number: String!, $categories: [String!]!) {
            createPatchNote(title: $title, contents: $contents, version_number: $version_number, categories: $categories) {
                id
            }
        }
        `, "createPatchNote",
      { title, contents, version_number, categories },
      true
    );
  },
  update_post: function (postId, newPost) {
    return query(
      `mutation ($postId: String!, $newPost: PostInput!){
          updatePost(postId: $postId, newPost: $newPost)
        }  
    `, "updatePost",
      { postId, newPost },
      true
    );
  },
  edit_post: function (postId, newPost) {
    return query(
      `mutation ($postId: String!, $newPost: PostInput!){
          editPost(postId: $postId, newPost: $newPost)
        }  
    `, "editPost",
      { postId, newPost },
      true
    );
  },
  delete_post: function (postId) {
    return query(
      `mutation ($postId: String!){
          deletePost(postId: $postId)
        }  
    `, "deletePost",
      { postId },
      true
    );
  },
  update_post_admin_data: function (postId, in_progress, notes) {
    return query(
      `mutation ($postId: String!, $in_progress: Boolean!, $notes: String!){
        updatePostAdminData(postId: $postId, in_progress: $in_progress, notes: $notes)
      }
      `, "updatePostAdminData",
      { postId, in_progress, notes },
      true
    )
  },
  clear_post_flags: function (postId) {
    return query(
      `mutation ($postId: String!){
          clearPostFlags(postId: $postId)
        }  
    `, "clearPostFlags",
      { postId },
      true
    );
  },
  clear_comment_flags: function (commentId) {
    return query(
      `mutation ($commentId: String!){
          clearCommentFlags(commentId: $commentId)
        }  
    `, "clearCommentFlags",
      { commentId },
      true
    );
  },
  add_duplicate: function (parentPostId, duplicatePostId) {
    return query(
      `mutation ($parentPostId: String!, $duplicatePostId: String!){
        addDuplicate(parentPostId: $parentPostId, duplicatePostId: $duplicatePostId)
      }
    `, "addDuplicate", { parentPostId, duplicatePostId }, true)
  },
  remove_duplicate: function (postId) {
    return query(
      `mutation ($postId: String!){
        removeDuplicate(postId: $postId)
      }
      `, "removeDuplicate", { postId }, true)
  },
  update_duplicate_valuable: function (postId, valuable) {
    return query(
      `mutation ($postId: String!, $valuable: Boolean!){
        updateDuplicateValuable(postId: $postId, valuable: $valuable)
      }
      `, "updateDuplicateValuable", { postId, valuable }, true)
  },
  find_similar_titles: function (title) {
    return query(
      `query ($title: String!){
        findSimilarTitles(title: $title){
          title
          id
        }
      }
    `, "findSimilarTitles", { title }, true)
  },
  create_comment: function (text, post_id, autoFavourite) {
    return query(
      `mutation ($text: String!, $post_id: ID!) {
            createComment(text: $text, post_id: $post_id) {
                id
                text
                upvotes
                author{
                    id
                    username
                    points
                    role
                }
                creation_date
                lastEdit
                isAnswer
                flags{
                  id
                }
            }
            ${autoFavourite ? "favourite(postId: $post_id)" : ""}
        }
        `, "createComment",
      { text, post_id, autoFavourite },
      true
    );
  },
  update_comment: function (id, text) {
    return query(
      `mutation ($id: ID!, $text: String!){
        updateComment(id: $id, text: $text)
      }
    `, "updateComment", { id, text }, true)
  },
  delete_comment: function (id) {
    return query(
      `mutation ($id: ID!){
        deleteComment(id: $id)
      }
    `, "deleteComment", { id }, true)
  },
  purge_user: function (userId) {
    return query(
      `mutation ($userId: ID!){
        purgeUser(userId: $userId)
      }
    `, "purgeUser", { userId }, true)
  },
  mark_comment: function (id, isAnswer) {
    return query(
      `mutation ($id: ID!, $isAnswer: Boolean!){
      markComment(id: $id, isAnswer: $isAnswer)
    }
    `, "markComment", { id, isAnswer }, true)
  },
  create_sub_comment: function (parentId, text) {
    return query(
      `mutation ($parentId: ID!, $text: String!){
        createSubComment(parentId: $parentId, text: $text){
            id
            text
            upvotes
            author{
                id
                username
                points
                role
            }
            creation_date
            lastEdit
            isAnswer
            flags{
              id
            }
        }
      }
      `
      , "replyComment", { parentId, text }, true)
  },
  read_message: function (messageId) {
    return query(
      `mutation ($messageId: String!){
        readMessage(messageId: $messageId)
      }
    `, "readMessage", { messageId }, true)
  },
  send_message: function ({ title, body, recipient }) {
    return query(
      `mutation ($title: String!, $body: String!, $recipient: String!){
        sendMessage(title: $title, body: $body, recipient: $recipient)
      }
    `, "sendMessage", { title, body, recipient }, true)
  },
  register_user: function (username, email, password) {
    return query(
      `mutation ($username: String!, $email: String!, $password: String!){
          registerUser(username: $username, email: $email, password: $password)
      }
    `, "registerUser",
      { username, email, password }
    );
  },
  update_user: function (userId, newUser) {
    return query(
      `mutation ($userId: String!, $newUser: UserInput!){
            updateUser(userId: $userId, newUser: $newUser)
        }
    `, "updateUser",
      { userId, newUser },
      true
    );
  },
  delete_user: function (userId) {
    return query(
      `mutation ($userId: String!){
          deleteUser(userId: $userId)
      }
    `, "deleteUser",
      { userId },
      true
    );

  },
  permaban_user: function (userId) {
    return query(
      `mutation ($userId: String!){
          permaBanUser(userId: $userId)
      }
    `, "permaBanUser",
      { userId },
      true
    );
  },
  user: function (id) {
    return query(
      `query ($id: String!) {
          user(id: $id) {
              username
              points
              upvotes_given
              upvotes_recieved
              comments{
                  id
              }
          }
      }
        `, "getUserById", { id })
  },
  all_users: function (page, pageSize, username, orderBy, order) {
    return query(`query ($page: Int!, $pageSize: Int!, $username: String, $orderBy: String, $order: String){
      allUsers(page: $page, pageSize: $pageSize, username: $username, orderBy: $orderBy, order: $order){
        users{
          id
          email
          creation_date
          username
          role
          points
          banned_until
          privateMessagesEnabled
        }
        hasNextPage
        hasPrevPage
        totalDocs
        limit
        totalPages
      }
    }`, "allUsers", { page, pageSize, username, orderBy, order }, true);
  },
  all_flags_users: function (page, pageSize, username, minFlags, maxFlags) {
    return query(`query ($page: Int!, $pageSize: Int!, $username: String, $minFlags: Int, $maxFlags: Int){
      allFlagsUsers(page: $page, pageSize: $pageSize, username: $username, minFlags: $minFlags, maxFlags: $maxFlags ){
        users{
          username
          flagCount
        }
        hasNextPage
        hasPrevPage
        totalDocs
        limit
        totalPages
      }
    }`, "allFlagsUsers", { page, pageSize, username, minFlags, maxFlags }, true);
  },
  all_flags_comments: function (page, pageSize, comment, username, date, minFlags, maxFlags) {
    return query(
      `query($page: Int!, $pageSize: Int!, $comment: String, $username: String, $date: String, $minFlags: Int, $maxFlags: Int) {
        flaggedComments(page: $page, pageSize: $pageSize, comment: $comment, username: $username, date: $date, minFlags: $minFlags, maxFlags: $maxFlags) {
          comments{
            id
            post_id
            creation_date
            author{
              username
            }
            text
            flagsCount
          }
          totalDocs
          totalPages
          limit
          hasPrevPage
          hasNextPage
        }
      }
    `, "getAllFlagsComments", { page, pageSize, comment, username, date, minFlags, maxFlags }, true)
  },
  all_flags_posts: function (page, pageSize, title, username, date, minFlags, maxFlags) {
    return query(
      `query($page: Int!, $pageSize: Int!, $title: String, $username: String, $date: String, $minFlags: Int, $maxFlags: Int) {
        flaggedPosts(page: $page, pageSize: $pageSize, title: $title, username: $username, date: $date, minFlags: $minFlags, maxFlags: $maxFlags){
          posts{
            id
            title
            creation_date
            author{
              username
              id
            }
            flags{
              id
            }
            flagsCount
          }
          totalDocs
          totalPages
          limit
          hasPrevPage
          hasNextPage
        }
      }
    `, "getAllFlagsPosts", { page, pageSize, title, username, date, minFlags, maxFlags }, true)
  },
  all_flags: function () {
    return query(
      `query {
        users{
          username
          flagCount
        }
        flaggedComments{
          id
          post_id
          creation_date
          author{
            username
          }
          flags{
            id
          }
        }
        flaggedPosts{
          id
          title
          creation_date
          author{
            username
            id
          }
          flags{
            id
          }
        }
      }
    `, "getAllFlags", {}, true)
  },
  all_audits: function (page, pageSize, searchType, searchAction, searchAuthor, startDate, endDate) {
    return query(
      `query AllAudits($page: Int, $pageSize: Int, $searchType: String, $searchAction: String, $searchAuthor: String, $startDate: String, $endDate: String) {
        allAudits(page: $page, pageSize: $pageSize, searchType: $searchType, searchAction: $searchAction, searchAuthor: $searchAuthor, startDate: $startDate, endDate: $endDate) {
          audits {
            action_type
            author {
              username
            }
            actioned_object
            creation_date
          }
          totalCount
          currentPage
          totalPages
        }
      }`,
      "getAllAudits",
      { page, pageSize, searchType, searchAction, searchAuthor, startDate, endDate },
      true
    );
  },
  user_by_name: function (username) {
    return query(
      `query ($username: String!){
            userByName(username: $username) {
              id
              email
              creation_date
              username
              role
              points
              banned_until
              privateMessagesEnabled
            }
        }
        `, "getUserByName",
      { username },
      true
    );
  },
  user_messages: function () {
    return query(
      `query {
        userMessages{
          id
          sender{
            username
            id
          }
          seen
          sent_date
          title
          body
          notification_message
          notification_message_postID
        }
    }`, "getMessages", {}, true)
  },
  user_posts_by_name: function (username, isAdmin) {
    return query(
      `query ($username: String!){
            userByName(username: $username) {
                id
                posts {
                    id
                    title
                    status
                    creation_date
                    upvotes
                    contents
                    version_number
                    comments {
                        id
                    }
                    countComments
                    categories
                    ${isAdmin ? "flags { username }" : ""}
                }
                favourites{
                    id
                    title
                    status
                    creation_date
                    upvotes
                    contents
                    version_number
                    comments {
                        id
                    }
                    author{
                      username
                    }
                    countComments
                    categories
                }
                comments{ 
                    id
                    text
                    upvotes
                    creation_date
                    post_id
                    ${isAdmin ? "flags { username }" : ""}
                }
                privateMessagesEnabled
            }
        }
        `, "getUserPostsByName",
      { username }, true
    );
  },
  me: function () {
    return query(
      `query  {
            me{
              user {
                id
                username
                points
                upvotes_given
                upvotes_recieved
                email
                creation_date
                role
                banned_until
                answers
                comments{
                    id
                }
                posts {
                    id
                }
                favourites { 
                  id
                }
                privateMessagesEnabled
                blocked_users {
                  id
                }
              }
              isBanned
              unreadMessages
            }
        }    
        `, "me",
      {},
      true
    );
  },
  assigned_posts: function () {
    return query(
      `query {
        me{
          user {
          assigned_posts{
            id
            title
            status
            upvotes
            comments {
              id
            }
            author{
              username
            }
            creation_date
            categories
          }
        }
      }
    }    
    `, "getAssignedPosts",
      {},
      true
    );
  },
  login: function (email, password) {
    return query(
      `query ($email: String!, $password: String!) {
            login(email: $email, password: $password){
                user {
                  username
                  points
                  upvotes_given
                  upvotes_recieved
                  email
                  creation_date
                  role
                  answers
                  banned_until
                  comments{
                      id
                  }
                  posts {
                      id
                  }
                }
                token
                isBanned
                unreadMessages
            }
        }        
        `, "login",
      { email, password }
    );
  },
  verify_account: function (email, token) {
    return query(
      `query ($email: String!, $token: String!){
            tokenConfirmation(email: $email, token: $token)
        }    
        `, "verifyAccount",
      { email, token }
    );
  },

  resend_token: function (email) {
    return query(
      `query ($email: String!){
            resendToken(email: $email)
        }    
        `, "resendVerificationToken",
      { email }
    );
  },
  send_import_email: function (email) {
    return query(
      `query ($email: String!){
            sendImportEmail(email: $email)
        }    
        `, "sendImportEmail",
      { email }
    );
  },
  delete_account: function () {
    return query(
      `mutation {
            deleteAccount
        }    
        `, "deleteAccount",
      {}, true
    );
  },
  vote_post: function (postId, downvote) {
    return query(
      `mutation ($postId: String!) {
            ${downvote ? "downvote" : "upvote"}Post(id: $postId) {
                id
                upvotes
                author {
                    username
                }
                comments{
                    id
                    upvotes
                }
            }
        }        
        `, "votePost",
      { postId },
      true
    );
  },
  vote_comment: function (commentId, downvote) {
    return query(
      `mutation ($commentId: ID!) {
            ${downvote ? "downvote" : "upvote"}Comment(id: $commentId) {
                id
                upvotes
                author {
                    username
                }
            }
        }        
        `, "voteOnComment",
      { commentId },
      true
    );
  },
  change_password: function (currentPassword, newPassword) {
    return query(
      `mutation ($currentPassword: String!, $newPassword: String!){
              changePassword(currentPassword: $currentPassword, newPassword: $newPassword)
          }
        `, "changePassword",
      { currentPassword, newPassword },
      true
    );
  },
  update_public_message_setting: function (privateMessagesEnabled) {
    return query(
      `mutation ($privateMessagesEnabled: Boolean!){
          updateprivateMessagesSetting(privateMessagesEnabled: $privateMessagesEnabled)
        }
    `, "updatePrivateMessageSetting",
      { privateMessagesEnabled }, true)
  },
  notification_emails: function () {
    return query(
      `query {
          notificationEmails
        }
    `, "getNotificationEmails",
      {},
      true
    );
  },
  update_notification_emails: function (newEmails) {
    return query(
      `mutation ($newEmails: [String!]!){
        updateNotificationEmails(emails: $newEmails)
      }
    `, "updateNotificationEmails",
      { newEmails },
      true
    );
  },
  other_settings: function () {
    return query(
      `query {
        otherSettings
      }
    `, "getOtherSettings",
      {},
      true
    );
  },
  update_other_settings: function (settings) {
    return query(
      `mutation ($settings: [String!]!){
        updateOtherSettings(settings: $settings)
      }
    `, "updateOtherSettings",
      { settings },
      true
    );
  },
  ip_blacklist: function () {
    return query(
      `query {
        ipBlackList
      }
    `, "getIpBlackList",
      {},
      true
    );
  },
  update_ip_blacklist: function (ips) {
    return query(
      `mutation ($ips: [String!]!){
        updateIpBlackList(ips: $ips)
      }
    `, "updateIpBlacklist",
      { ips },
      true
    );
  },
  username_blacklist: function () {
    return query(
      `query {
        usernameBlackList
      }
    `, "getUsernameBlacklist",
      {},
      true
    );
  },
  update_username_blacklist: function (usernames) {
    return query(
      `mutation ($usernames: [String!]!){
        updateUsernameBlackList(usernames: $usernames)
      }
    `, "updateUsernameBlacklist",
      { usernames },
      true
    );
  },
  send_password_reset: function (email) {
    return query(
      `query ($email: String!){
          sendPasswordResetToken(email: $email)
        }
    `, "sendPasswordReset",
      { email }
    );
  },
  reset_password: function (email, token, password) {
    return query(
      `query ($email: String!, $token: String!, $password: String!){
          passwordResetConfirmation(email: $email, token: $token, password: $password)
        }
    `, "resetPassword",
      { email, token, password }
    );
  },
  finish_import: function (email, token, password) {
    return query(
      `query ($email: String!, $token: String!, $password: String!){
          finishImport(email: $email, token: $token, password: $password)
        }
    `, "finishImport",
      { email, token, password }
    );
  },
  check_email: function (email) {
    return query(
      `query ($email: String!){
        checkEmail(email: $email)
      }
   `, "checkEmailExists", { email })
  },
  check_username: function (username) {
    return query(
      `query ($username: String!){
        checkUsername(username: $username)
      }
   `, "checkUsernameExists", { username })
  },
  get_categories_stats: function () {
    return query(
      `query ($type: String!){
        statsByType(type: $type){
          id
          stats_type
          stats_value{
            name
            value
          }
        }
      }
    `, "getCategoryStats", { type: "categories" })
  },
  unfavourite: function (postId) {
    return query(
      `mutation ($postId: ID!){
      unfavourite(postId: $postId)
    }`, "unfavouritePost", { postId }, true)
  },
  favourite: function (postId) {
    return query(
      `mutation ($postId: ID!){
      favourite(postId: $postId)
    }`, "favouritePost", { postId }, true)
  },
  unpin_post: function (postId) {
    return query(
      `mutation ($postId: ID!){
      unpinPost(postId: $postId)
    }`, "unpinPost", { postId }, true)
  },
  pin_post: function (postId) {
    return query(
      `mutation ($postId: ID!){
      pinPost(postId: $postId)
    }`, "pinPost", { postId }, true)
  },
  flag_post: function (postId) {
    return query(
      `mutation ($postId: String!){
      flagPost(postId: $postId)
    }`, "flagPost", { postId }, true)
  },
  unflag_post: function (postId) {
    return query(
      `mutation ($postId: String!){
      unflagPost(postId: $postId)
    }`, "unflagPost", { postId }, true)
  },
  toggle_hide_post: function (postId) {
    return query(
      `mutation ($postId: String!){
        toggleHidePost(postId: $postId)
    }`, "toggleHidePost", { postId }, true)
  },
  flag_comment: function (commentId) {
    return query(
      `mutation ($commentId: ID!){
      flagComment(commentId: $commentId)
    }`, "flagComment", { commentId }, true)
  },
  unflag_comment: function (commentId) {
    return query(
      `mutation ($commentId: ID!){
      unflagComment(commentId: $commentId)
    }`, "unflagComment", { commentId }, true)
  },
  update_block_user: function (userId, value) {
    return query(
      `mutation ($userId: String!, $value: Boolean!){
      updateBlockUserValue(userId: $userId, value: $value)
    }`, "updateBlockUser", { userId, value }, true)
  },
  feed_data: function () {
    return query(
      `query {
        feedData{
          username
          date
          type
          post_id
          postTitle
        }
      }
    `, "getFeed", {}, true)
  },
  get_versions: function (noCache) {
    return query(`query {
        versions
      }`, "getVersions", {}, true)
    // if (noCache) return func();
    // else return grabLocal("versions", func);
  },
  update_versions: function (newVersions) {
    return query(`mutation ($newVersions: [String!]!){
        updateVersions(newVersions: $newVersions)
      }`, "updateVersions", { newVersions }, true);
  },
  update_statuses: function (newStatuses) {
    return query(`mutation ($newStatuses: [String!]!){
        updateStatuses(newStatuses: $newStatuses)
      }`, "updateStatuses", { newStatuses }, true);
  },
  update_categories: function (newCategories) {
    return query(`mutation ($newCategories: [String!]!){
        updateCategories(newCategories: $newCategories)
      }`, "updateCategories", { newCategories }, true);
  },
  auto_tags: function () {
    return query(`query {
        autoTags 
    }`, "getAutoTags", {}, true)
  },
  update_auto_tags: function (newAutoTags) {
    return query(`mutation($newAutoTags: [String!]!) {
        updateAutoTags(newAutoTags: $newAutoTags) 
    }`, "updateAutotags", { newAutoTags }, true)
  },
  leaderboard_snap: function (username, skip) {
    return query(`query ($username: String!, $skip: Int!){
      leaderboardSnap(username: $username, skip: $skip){
        users{
          points
          username
        }
        hasNextPage
        hasPrevPage
        totalDocs
        limit
        totalPages
      }
    }`, "leaderboardSnap", { username, skip });
  },
  //fake requests TODO change at some point yes
  get_categories: (noCache) => {
    const func = () => query(`query {
      categories
    }`, "getCategories", {}, true)
    if (noCache) return func();
    else return grabLocal("categories", func);
  },
  get_statuses: function () {
    return query(`query {
        statuses
      }`, "getStatuses", {}, true)
  },
  get_statuses_restricted: async (restrictedList) => {
    const data = await query(`query {
      statuses
    }`, "getStatuses", {}, true)

    if (restrictedList) {
      data.statuses = data.statuses.filter(status => ["open", "closed", "hidden"].some(substring => status.includes(substring)))
      return data
    }
    else {
      data.statuses = data.statuses.filter(status => ["open", "in_progress", "rejected", "solved", "fixed_internally", "known_issue", "closed", "hidden"].some(substring => status.includes(substring)))
      return data
    }
  },
  get_emailWhitelist: function (noCache) {
    return query(`query {
      whiteList
      }`, "getWhiteList", {}, true)
  },
  update_emailWhiteList: function (newWhitelist) {
    return query(`mutation ($newWhitelist: [String!]!){
      updateWhitelist(newWhitelist: $newWhitelist)
      }`, "updateWhitelist", { newWhitelist }, true);
  },
};

async function grabLocal(key, requestfunction) {
  let data = getLocal(key);
  if (!data || Object.entries(data).length < 1 || !config.sessionStorage) {
    data = await requestfunction();
    if (config.sessionStorage) setLocal(key, data);
  }
  return data;
}

function setLocal(key, data) {
  sessionStorage.setItem(key, JSON.stringify(data));
}

function getLocal(key) {
  return JSON.parse(sessionStorage.getItem(key));
}

async function query(queryString, opName, variables = {}, token = false) {
  await new Promise(resolve => setTimeout(resolve, config.simulatedLatency));

  const apolloFetch = createApolloFetch({ uri: api_url });
  apolloFetch.use(({ request, options }, next) => {
    if (!options.headers) {
      options.headers = {}; // Create the headers object if needed.
    }
    if (getIdToken() && token) options.headers["token"] = getIdToken();

    next();
  });
  const response = await apolloFetch({ query: queryString, variables, opName });
  if (response.errors) {
    if (response.errors[0].message === "Context creation failed: Your session has expired. Sign in again.")
      clearIdToken()
    return { errors: response.errors[0].message };
  }
  return response.data;
}
