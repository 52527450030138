import React, { useState, useEffect, useRef, useMemo } from "react";
import { mobileRes } from "./../Nav/SideNav";
import "../Shared/PostBody.css";
import "./PostPage.css";
import PostBody from "./PostBody"
import PostPageControls from "./PostPageControls"
import { isAdmin, isModerator } from "../../util/misc";

const PostPage = ({ user, updateParentWithVote, updateUser, windowState, functions: { updateLeftBar } }) => {
  const [showEdit, updateShowEdit] = useState(false);
  const [showDelete, updateShowDelete] = useState(false);
  const [post, updatePost] = useState({});
  const [isFetching, updateIsFetching] = useState(false);
  const [isAuthor, updateIsAuthor] = useState(false);

  const childRef = useRef(null);
  const handleComment = () => {
    childRef.current.showComment();
  };

  const handleEdit = () => {
    childRef.current.openEdit();
  }

  const handleHidePost = () => {
    childRef.current.handleHidePost();
  }

  const handleDelete = () => {
    childRef.current.openDelete();
  }

  const handleFlagPost = (value) => {
    childRef.current.handleFlagPost(value);
  }

  const handleFavourites = (value) => {
    childRef.current.handleFavourites(value);
  }

  const handlePin = (value) => {
    childRef.current.handlePin(value);
  }

  const handleVote = (value) => {
    childRef.current.handleVote(value);
  }

  //On Mount
  useEffect(() => {
    updateLeftBar(true)
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //On Unmount
  useEffect(() => () => updateLeftBar(false), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (windowState.windowWidth >= mobileRes) {
      updateLeftBar(true)
    }
    else {
      updateLeftBar(false)
    }
  }, [windowState]); // eslint-disable-line react-hooks/exhaustive-deps

  const hasFlaggedPost = useMemo(() => post.flags && user.data && post.flags.findIndex(u => u.id === user.data.id) !== -1, [post, user]);
  const isFavourite = useMemo(() => user?.data?.favourites?.map(p => p.id).includes(post.id), [post.id, user]);
  const isLiked = useMemo(() => post.likeGiven, [post.likeGiven]);
  const isPinned = useMemo(() => post.isPinned, [post.isPinned]);
  const checkIfAdmin = useMemo(() => user?.data && isAdmin(user?.data?.role), [user]);
  const checkIfMod = useMemo(() => user?.data && isModerator(user?.data?.role), [user]);
  const isPatchNotes = useMemo(() => post.is_patch_note, [post.is_patch_note]);
  return <div className="post_page">
    {
        <div className="post_page_left_bar">
          <PostPageControls
          postId={post.id}
            user={user}
            updateUser={updateUser}
            isFetching={isFetching}
            showEdit={showEdit}
            showDelete={showDelete}
            hasFlaggedPost={hasFlaggedPost}
            isLiked={isLiked}
            isPatchNotes={isPatchNotes}
            isAuthor={isAuthor}
            isAdmin={checkIfAdmin}
            isModerator={checkIfMod}
            isFavourite={isFavourite}
          isPinned={isPinned}
            isHidden={post.hidden}
          functions={{ handleComment, handleEdit, handleDelete, handleFlagPost, handleFavourites, handleVote, handleHidePost, handlePin }}>
          </PostPageControls>
      </div>
    }
    <div className="post_page_right">
      <PostBody
        ref={childRef}
        isFetching={isFetching}
        post={post}
        user={user}
        isAuthor={isAuthor}
        updateParentWithVote={updateParentWithVote}
        updateUser={updateUser}
        windowState={windowState}
        showEdit={showEdit}
        showDelete={showDelete}
        functions={{ updateIsFetching, updatePost, updateIsAuthor, updateShowEdit, updateShowDelete }}></PostBody>
    </div>
  </div >
};

export default PostPage;