import React from "react";
import ReactHtmlParser from "react-html-parser";

export default function PostPageRichContent({ post, hideclass }) {
    const isUrl = word => {
        const urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm; // eslint-disable-line no-useless-escape
        return word.match(urlPattern)
    }

    const handleLink = w => {

        if ((w.includes("youtube") || w.includes("youtu")) && w.includes("<a")) {
            return ReactHtmlParser(w.replace(/<a\s+href="([^"]+)"[^>]*>([^<]+)<\/a>/gm, (match, url, text) => {
                return `<iframe src="${url.replace("youtu.be/", "www.youtube.com/watch?v=").replace("watch?v=", "embed/").replace(/&[^&]+/gm, '')}" height="315" alt="Preview" > </iframe>`;
            }
            ))
        }
        else if ((w.includes("youtube") || w.includes("youtu")) && isUrl(w)) {
            return ReactHtmlParser(w.replace(/(https?:\/\/[^\s]+)/gm, (match, url, text) => {
                return `<iframe src="${url.replace("youtu.be/", "www.youtube.com/watch?v=").replace("watch?v=", "embed/").replace(/&[^&]+/gm, '')}" height="315" alt="Preview" > </iframe>`;
            }))
        }
        else if (w.includes("imgur") && !w.includes("/a/") && w.includes("<a")) {
            return ReactHtmlParser(w.replace(/<a\s+href="([^"]+)"[^>]*>([^<]+)<\/a>/gm, (match, url, text) => {
                const link = url.includes('.jpeg') ? url : url + '.jpeg';
                return `<img src="${link}" height="315" alt="Preview" />`;
            }
            ))
        }
        else if (w.includes("imgur") && !w.includes("/a/") && isUrl(w)) {
            return ReactHtmlParser(w.replace(/(https?:\/\/[^\s]+)/gm, (match, url, text) => {
                const link = url.includes('.jpeg') ? url : url + '.jpeg';
                return `<img src="${link}" height="315" alt="Preview" />`;
            }
            ))
        }
        else if (isUrl(w)) {
            return ReactHtmlParser(w.replace(/(https?:\/\/[^\s]+)/gm, '<a key="$1" href="$1" rel="noopener noreferrer" target="_blank">$1</a>'))
        } 
        else {
            return ReactHtmlParser(w)
        }
    }

    return (
        <div className={hideclass ? "" : "post_main_body"}>
            {(post.contents || post.comment)?.split(/(<img[^>]*>)/).map((w, i) => {
            if (w.startsWith("<img")) {
                return handleLink(w); // If it's an <img> tag, return it as is
            } else {
                return w.split(/(<a[^>]*>.*?<\/a>)/).map((part, index) => {
                    return handleLink(part)
                })
            }
        })}
    </div>
    )

}
