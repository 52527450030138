// UserFavoritePostsPanel.js
import React, { useState, useEffect } from 'react';
import UsePagination from '../PostList/Paginator';
import logo_window from './../../assets/window_icon.svg'
import "react-tabs/style/react-tabs.css";
import "./InboxDashboard.css";
import "./../UserPublic/UserPublic.css";
import "react-tabs/style/react-tabs.css";
import InboxMessage from "./InboxMessage";


const InboxPMList = ({ pms, numberDisplayed, replyAction, readMessage }) => {
    const [totalPages, updateTotalPages] = useState(0);
    const [selectedPage, updateSelectedPage] = useState(1);

    useEffect(() => {
        updateTotalPages(Math.ceil(pms.length / numberDisplayed))
    }, [pms]); // eslint-disable-line react-hooks/exhaustive-deps


    if (pms.length === 0) return <h1 className="nice_h1">No personal messages found</h1>;

    return (
        <div className='user_panel'>
            <div className="comment_window_pop_up_title">
                <img className="logo_window" src={logo_window} alt="Logo Window" />
                <p className='title_comment_pop_up' style={{ textAlign: "start" }}>Personal Messages {pms.length > 0 ? `(${pms.length})` : null} </p>
            </div>
            <div className='user_public_body'>
                {totalPages === 0 || totalPages === 1 || pms.length <= 0 ? <></> : <UsePagination pages={totalPages} selectedPage={selectedPage} functions={{ updateSelectedPage }} ></UsePagination>}
                {pms.slice((selectedPage - 1) * numberDisplayed, selectedPage * numberDisplayed).map((message, i) => (
                    <InboxMessage message={message} reply={replyAction(message)} key={i} readMessage={readMessage(message.id)} />
                ))}

                {totalPages === 0 || totalPages === 1 || pms.length <= 0 ? <></> : <UsePagination pages={totalPages} selectedPage={selectedPage} functions={{ updateSelectedPage }} ></UsePagination>}
            </div>
        </div>
    )
};

export default InboxPMList;
