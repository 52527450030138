import React, { useState, useEffect } from "react";
import ExpandableControl from "../../Shared/ExpandableControl";
import styled from 'styled-components'
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import AdminFlagsUsers from "./AdminFlagsUsers";
import AdminFlagsComments from "./AdminFlagsComments";
import AdminFlagsPosts from "./AdminFlagsPosts";

const AdminFlags = ({ defaultExpanded }) => {
  const localIndex = JSON.parse(sessionStorage.getItem("flags_tab_index"));

  const [currentTabIndex, setTabIndex] = useState(localIndex ? localIndex : 0);


  useEffect(() => {
    sessionStorage.setItem("flags_tab_index", JSON.stringify(currentTabIndex))
  }, [currentTabIndex])



  return (
    <ExpandableControl
      className="admin_flags"
      height={(defaultExpanded ? null : "670px")}
      header="Flags"
      defaultExpanded={defaultExpanded}
    >
      <div style={{ height: "100%" }}>
        <Tabs selectedIndex={currentTabIndex} onSelect={(tabIndex) => { setTabIndex(tabIndex); }} style={{ width: "100%", margin: "0", padding: "0" }}>
          <StyledTabList>
            <Tab>Users</Tab>
            <Tab>Comments</Tab>
            <Tab>Posts</Tab>
          </StyledTabList>
          <TabPanel>
            <Styles>
              <AdminFlagsUsers defaultExpanded={true} />
            </Styles>
          </TabPanel>
          <TabPanel>
            <Styles>
              <AdminFlagsComments defaultExpanded={true} />
            </Styles>
          </TabPanel>
          <TabPanel>
            <Styles>
              <AdminFlagsPosts defaultExpanded={true} />
            </Styles>
          </TabPanel>
        </Tabs>
      </div>
    </ExpandableControl>
  );
};

export default AdminFlags;

const Styles = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;  
  table {
    width: 100%;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.4rem;
      text-align: center;
      :last-child {
        border-right: 0;
      }
    }
  }
`

const StyledTabList = styled(TabList)`
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin: 0;
  padding: 0;

  .react-tabs__tab:focus {
    content: ""; /* Resetting the pseudo-element content */
    color: white;
    box-shadow: none;
    outline: none; /* Remove outline */
    outline-color: transparent;
  }

  .react-tabs__tab:focus:after {
    background: transparent
  }

  > .react-tabs__tab {
    flex: 1;
    text-align: center;
    padding: 0;
    cursor: pointer;
    border-bottom: 1px solid transparent; /* Set border to transparent */
    transition: border-bottom-color 0.3s ease, color 0.3s ease; /* Added color transition */
    outline: none; /* Remove outline */

  }

  > .react-tabs__tab--selected {
    border-color: transparent;
    padding-bottom: 15px;
    border-bottom: 2px solid #ccc;
    outline: none; /* Remove outline */
  }
`;