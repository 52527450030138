// UserFavoritePostsPanel.js
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import UsePagination from '../PostList/Paginator';
import internal_fetch from "../../util/local-api";
import Spinner from "../Shared/Spinner";
import logo_window from './../../assets/window_icon.svg'
import Categories from "../Shared/Categories";
import "react-tabs/style/react-tabs.css";
import "./UserPublic.css";
import UpvoteDisplay from '../Shared/UpvoteDisplay';
import ArrowDropUpIcon from '@material-ui/icons/Message';
import QuestionIcon from '@material-ui/icons/Help';
import HealingIcon from '@material-ui/icons/Healing';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import DoneIcon from '@material-ui/icons/Done';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import BuildIcon from '@material-ui/icons/Build';
import LockIcon from '@material-ui/icons/Lock';
import ToolTip from "../Shared/ToolTip"
import ReactTimeAgo from 'react-time-ago'
import { truncateText } from '../../util/misc';

const statusMap = function (status) {
  switch (status) {
    case "in_progress": return BuildIcon
    case "solved": return DoneIcon
    case "rejected": return NotInterestedIcon
    case "closed": return LockIcon
    case "fixed_internally": return HealingIcon
    case "known_issue": return FolderSharedIcon
    case "open": return "div";
    default: {
      return QuestionIcon;
    }
  }

}

const UserFavoritePostsPanel = ({ username, isAdmin, numberDisplayed }) => {
  const [userFavourites, updateUserFavourites] = useState([]);
  const [totalPages, updateTotalPages] = useState(0);
  const [selectedPage, updateSelectedPage] = useState(1);
  const [isFetching, updateIsFetching] = useState(true);

  useEffect(() => {
    (async function iife() {
      //checks if user login check has done or not.
      if (isAdmin === undefined) return

      updateIsFetching(true);
      const { userByName } = await internal_fetch.user_posts_by_name(username, isAdmin);
      if (userByName) {
        updateUserFavourites(userByName.favourites);
      }
      updateIsFetching(false);
    })();
  }, [username, isAdmin]);

  useEffect(() => {
    updateTotalPages(Math.ceil(userFavourites.length / numberDisplayed))
  }, [userFavourites]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isFetching) return <Spinner loading={isFetching} />;

  if (!isFetching && userFavourites.length === 0) return <h1 className="nice_h1">No Favourites found</h1>;

  return (
    <div className='user_panel'>
      <div className="comment_window_pop_up_title">
        <img className="logo_window" src={logo_window} alt="Logo Window" />
        <p className='title_comment_pop_up'>Favourited Posts by {truncateText(username, 30)}</p>
      </div>
      <div className='user_public_body'>
        {totalPages === 0 || totalPages === 1 || userFavourites.length <= 0 ? <></> : <UsePagination pages={totalPages} selectedPage={selectedPage} functions={{ updateSelectedPage }} ></UsePagination>}


        {userFavourites.slice((selectedPage - 1) * numberDisplayed, selectedPage * numberDisplayed).map((post, i) => (
          <div className="post" key={post.id}>
            <div className="post_upvotes">
              <UpvoteDisplay post={post} disabled={true} upvotes={post.upvotes} userVoted={false} action={async () => { }} />
              <div className="posted_by"> {post.author ? <Link style={{ padding: "5px 0 15px" }} to={`/user/${post.author.username}`}> {truncateText(post.author.username, 20)} </Link> : <b>deleted</b>}</div>
            </div>
            <div className="post_contents">
              <div className="post_title">
                {!["open"].includes(post.status) ? <div style={{ marginTop: "-10px" }}>
                  <ToolTip
                    Icon={statusMap(post.status)}
                    text={post.status}
                    color="rgb(230, 147, 68)"
                  />
                </div> : null}

                <div className='post_title_div' style={{ width: "100%" }}>
                  <Link to={`/post/${post.id}`}>
                    <h2>{post.title}</h2>
                  </Link>
                </div>
                <div className='post_list_controls_div'>
                  <div className="date_time_by" style={{ marginRight: "5px" }}>
                    <ReactTimeAgo date={+post.creation_date} />
                  </div>
                </div>
              </div>

              <div className="version_number" >{post.version_number}</div>
              <div className="post_content_body"> <p>{post.contents.replace(/(<([^>]+)>)/gi, "")}</p></div>
              <Categories categories={post.categories} />
              <div className='comments_number' style={{ width: "0px", marginRight: "45px" }}> <span>{post.countComments}</span> <ArrowDropUpIcon style={{ fontSize: "20px", marginBottom: "-5px", marginLeft: "2px" }} /></div>

            </div>

          </div>
        ))}

        {totalPages === 0 || totalPages === 1 || userFavourites.length <= 0 ? <></> : <UsePagination pages={totalPages} selectedPage={selectedPage} functions={{ updateSelectedPage }} ></UsePagination>}
      </div>
    </div>
  )
};

export default UserFavoritePostsPanel;
