import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import JavascriptTimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { checkIfLoggedIn, clearIdToken } from "./util/auth";
import { debounce, isAdmin } from "./util/misc";
import internal_fetch from "./util/local-api";
import Nav from "./components/Nav/Nav";
import PostList from "./components/PostList/PostList";
import PatchNotesList from "./components/PatchNotesList/PatchNotesList";
import CategoryData from "./components/Categories/CategoryData";
import PostPage from "./components/PostPage/PostPage.js";
import Login from "./components/Login/Login.js";
import Confirmation from "./components/Login/Confirmation.js";
import ProfileDashboard from "./components/Profile/ProfileDashboard";
import InboxDashboard from "./components/Inbox/InboxDashboard";
import CreateAPostDashboard from "./components/CreateAPost/CreateAPostDashboard";
import AdminPanel from "./components/Admin/AdminPanel";
import UserPublic from "./components/UserPublic/UserPublic";
import PrivateRoute from "./PrivateRoute";
import { ClosedBetaPrivateRouter } from "./PrivateRoute";
import ResetPassword from "./components/Login/ResetPassword";
import AssignedPosts from "./components/AssignedPosts/AssignedPosts";
import Leaderboard from "./components/Leaderboard/Leaderboard";
import LatestResolved from "./components/LatestResolved/LatestResolved";
import { isClosedBeta } from "./util/misc";

import "./App.css";

JavascriptTimeAgo.locale(en);


function App() {
  const [user, updateUser] = useState({ isLoggedIn: false, data: undefined });
  const [isFetching, updateIsFetching] = useState(false);
  const [windowState, setWindowState] = useState({});
  const [usesLeftBar, updateLeftBar] = useState(false);

  useEffect(() => {
    (async function iife() {
      if (checkIfLoggedIn()) {
        updateIsFetching(true);
        const response = await internal_fetch.me();
        updateIsFetching(false);
        if (response.me) {
          if (response.me.isBanned) clearIdToken();
          else updateUser({ isLoggedIn: true, data: { ...response.me.user, unreadMessages: response.me.unreadMessages } });
        } else clearIdToken();
      }
      else updateUser({ isLoggedIn: false, data: false })
    })();
  }, []);

  useEffect(() => {
    const debouncedResize = debounce(function handleResize() {
      const newWindowState = {
        isMobile: window.innerWidth <= 600,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight
      }

      if (
        newWindowState.isMobile !== windowState.isMobile ||
        newWindowState.windowWidth !== windowState.windowWidth || newWindowState.windowHeight !== windowState.windowHeight
      ) { setWindowState(newWindowState) }
    }, 100)
    debouncedResize()
    window.addEventListener('resize', debouncedResize)
    return _ => {
      window.removeEventListener('resize', debouncedResize)
    }
  })

  const pushVote = (id, downvote) => {
    const { ...tmpUser } = user;
    if (downvote)
      tmpUser.data.upvotes_given = tmpUser.data.upvotes_given.filter(
        post => post !== id
      );
    else tmpUser.data.upvotes_given.push(id);
    updateUser(tmpUser);
  };

  return (
    <div id="App">
      <div className="bg-image"></div>
      <Router>
        <div id="stepcontent">
          {!isClosedBeta() ? <div className="top-image"></div> : <></>}
          <Nav
            usesLeftBar={usesLeftBar}
            user={user}
            isMobile={windowState.isMobile}
            updateUser={updateUser}
          />
          <div className="steps">
            <Switch>
              <ClosedBetaPrivateRouter exact path={["/", "/search"]}>
                <PostList
                  user={user}
                  updateUser={updateUser}
                  pushVote={pushVote}
                  userUpvotes={user && user.data ? user.data.upvotes_given : null}
                  isAdmin={user.data && isAdmin(user.data.role)}
                  functions={{ updateLeftBar }}
                  windowState={windowState}
                />
              </ClosedBetaPrivateRouter>
              <ClosedBetaPrivateRouter path="/patchnotes">
                <PatchNotesList
                  user={user}
                  updateUser={updateUser}
                  pushVote={pushVote}
                  userUpvotes={user && user.data ? user.data.upvotes_given : null}
                  isAdmin={user.data && isAdmin(user.data.role)}
                  functions={{ updateLeftBar }}
                  windowState={windowState}
                />
              </ClosedBetaPrivateRouter>
              <Route path="/categories">
                <CategoryData />
              </Route>
              <ClosedBetaPrivateRouter path="/latest">
                <LatestResolved
                  user={user}
                  updateUser={updateUser}
                  functions={{ updateLeftBar }}
                  windowState={windowState} />
              </ClosedBetaPrivateRouter>
              <ClosedBetaPrivateRouter path="/leaderboard">
                <Leaderboard
                  user={user}
                  updateUser={updateUser}
                  windowState={windowState}
                  isAdmin={user.data && isAdmin(user.data.role)}
                  functions={{ updateLeftBar }} />
              </ClosedBetaPrivateRouter>
              <ClosedBetaPrivateRouter path="/post/:id">
                <PostPage functions={{ updateLeftBar }}
                  user={user}
                  updateUser={updateUser}
                  updateParentWithVote={pushVote}
                  windowState={windowState} />
              </ClosedBetaPrivateRouter>
              <ClosedBetaPrivateRouter path="/user/:username">
                <UserPublic
                  user={user}
                  updateUser={updateUser}
                  windowState={windowState}
                  isLoggedIn={user.isLoggedIn}
                  isAdmin={user.data && isAdmin(user.data.role)}
                  functions={{ updateLeftBar }}
                />
              </ClosedBetaPrivateRouter>
              <Route path="/confirm/:token">
                <Confirmation />
              </Route>
              <Route path="/import/:token">
                <ResetPassword importAccount={true} />
              </Route>
              <Route path="/login">
                <Login
                  user={user}
                  updateUser={updateUser}
                  functions={{ updateLeftBar }}
                  methods={{
                    updateUser
                  }}
                  windowState={windowState}
                />
              </Route>
              <Route path="/reset/:token">
                <ResetPassword />
              </Route>
              <PrivateRoute path="/admin_panel">
                <AdminPanel user={user}
                  updateUser={updateUser}
                  functions={{ updateLeftBar }}
                  windowState={windowState} />
              </PrivateRoute>
              <PrivateRoute path="/assigned_posts">
                <AssignedPosts user={user}
                  updateUser={updateUser}
                  functions={{ updateLeftBar }}
                  windowState={windowState} />
              </PrivateRoute>
              <PrivateRoute path="/profile">
                <ProfileDashboard
                  user={user}
                  updateUser={updateUser}
                  functions={{ updateLeftBar }}
                  windowState={windowState}
                  isFetching={isFetching} />
              </PrivateRoute>
              <PrivateRoute path="/inbox">
                <InboxDashboard
                  user={user}
                  updateUser={updateUser}
                  functions={{ updateLeftBar }}
                  subtractUnreadCount={
                  () => {
                    const tmpUser = { ...user };
                    tmpUser.data.unreadMessages--;
                    updateUser(tmpUser);
                  }
                } windowState={windowState} />
              </PrivateRoute>
              <PrivateRoute exact path="/post">
                <CreateAPostDashboard
                  user={user}
                  updateUser={updateUser}
                  windowState={windowState}
                  functions={{ updateLeftBar }} />
              </PrivateRoute>
              <Redirect to="/" />
            </Switch>
          </div>
        </div>

      </Router>
    </div>
  );
}

export default App;
