import React, { useState, useEffect } from "react";
import Spinner from "../Shared/Spinner";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import internal_fetch from "../../util/local-api";
import "./InboxDashboard.css";
import "react-tabs/style/react-tabs.css";
import MessageModal from "./MessageModal";
import InboxMessageList from "./InboxMessageList";
import InboxPMList from "./InboxPMList";
import InboxMessagesReadList from "./InboxMessagesReadList";
import SideNav from "../Nav/SideNav";
import { mobileRes } from "./../Nav/SideNav";
import InboxSideNavContent from "./InboxSideNavContent";

const LOAD_INCREMENT = 20;

const InboxDashboard = ({ windowState, user, updateUser, subtractUnreadCount, functions: { updateLeftBar } }) => {
    const [userMessages, updateUserMessages] = useState([]);
    const [isFetching, updateIsFetching] = useState(true);
    const [numberDisplayed, updateNumberDisplayed] = useState(LOAD_INCREMENT);
    const [currentTabIndex, setTabIndex] = useState(0);
    const [selectedMessage, updateSelectedMessage] = useState({});
    const [displayReplyModal, updateReplyModal] = useState(false);

    //On Mount
    useEffect(() => { updateLeftBar(true) }, []); // eslint-disable-line react-hooks/exhaustive-deps
    //On Unmount
    useEffect(() => () => updateLeftBar(false), []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { updateLeftBar(windowState.windowWidth >= mobileRes) }, [windowState]); // eslint-disable-line react-hooks/exhaustive-deps


    const readMessage = (id) => async () => {
        await internal_fetch.read_message(id);
        const [...tmpMessages] = userMessages;
        tmpMessages[tmpMessages.findIndex(d => d.id === id)].seen = true;
        updateUserMessages(tmpMessages);
        subtractUnreadCount()
    }

    const replyToMessage = async () => {
        const response = await internal_fetch.read_message(selectedMessage.id);
        if (response.readMessage) {
            subtractUnreadCount();
        }
    }

    const replyAction = (message) => () => {
        const { ...newMessage } = message
        newMessage.recipient = newMessage.sender.id;
        newMessage.username = newMessage.sender.username;
        newMessage.title = `RE:${message.title}`
        newMessage.body = "";
        updateReplyModal(true);
        updateSelectedMessage(newMessage)
    }

    useBottomScrollListener(() => {
        let tmpDisplayedNumber = 0;
        const comparativeList = currentTabIndex ? userMessages.filter(message => message.seen) : userMessages.filter(message => !message.read);
        if (numberDisplayed === comparativeList.length) return;
        if (numberDisplayed + LOAD_INCREMENT > comparativeList.length) {
            tmpDisplayedNumber = comparativeList.length;
        } else {
            tmpDisplayedNumber = numberDisplayed + LOAD_INCREMENT;
        }
        updateNumberDisplayed(tmpDisplayedNumber);
    });


    useEffect(() => {
        (async function iife() {
            updateIsFetching(true);
            const { userMessages } = await internal_fetch.user_messages();
            if (userMessages) {
                updateUserMessages(userMessages);
            }
            updateIsFetching(false);
        })();
    }, []);


    const notifications = userMessages.filter(m => !m.seen).filter(m => m.notification_message)
    const pms = userMessages.filter(m => !m.seen).filter(m => !m.notification_message)

    return (
        <div className="">
            <div className="leaderboard_screen">

                <div className="leaderboard_controls">
                    <SideNav user={user} updateUser={updateUser}>
                        <InboxSideNavContent currentTabIndex={currentTabIndex} setTabIndex={setTabIndex}
                            notifications={notifications.length > 0 ? `(${notifications.length})` : null}
                            pms={pms.length > 0 ? `(${pms.length})` : null} />
                    </SideNav>
                </div>
                <div className="user_public_main_body">
                    {isFetching ? <Spinner loading={isFetching} /> :

                    <div style={{ width: "100%", padding: "20px" }}>
                        <Tabs selectedIndex={currentTabIndex} onSelect={(tabIndex) => { setTabIndex(tabIndex); if (currentTabIndex !== tabIndex) updateNumberDisplayed(LOAD_INCREMENT) }}>
                            <TabList style={{ display: "none" }}>
                                <Tab>Notifications {notifications.length > 0 ? `(${notifications.length})` : null} </Tab>
                                <Tab>Personal Messages  {pms.length > 0 ? `(${pms.length})` : null}  </Tab>
                                <Tab>Read</Tab>
                            </TabList>
                            <TabPanel>
                                <InboxMessageList notifications={notifications} numberDisplayed={numberDisplayed} replyAction={replyAction} readMessage={readMessage} />
                            </TabPanel>
                            <TabPanel>
                                <InboxPMList pms={pms} numberDisplayed={numberDisplayed} replyAction={replyAction} readMessage={readMessage} />
                            </TabPanel>
                            <TabPanel>
                                <InboxMessagesReadList userMessages={userMessages.filter(m => m.seen)} numberDisplayed={numberDisplayed} replyAction={replyAction} />
                            </TabPanel>
                        </Tabs>
                        <MessageModal windowState={windowState} message={selectedMessage} username={selectedMessage.username} triggerUpdate={replyToMessage} updateModal={updateReplyModal} modalShown={displayReplyModal} />
                        </div>}
                </div>
            </div>
        </div >
    );
};

export default InboxDashboard;
