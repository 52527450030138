import React, { useState } from "react";
import FancyButton from "../Shared/FancyButton";
import internal_fetch from "../../util/local-api";
import isEmail from "isemail";
import { useAlert } from 'react-alert'
import LoginFieldText from "./LoginFieldText";

const CreateAccountBox = ({ updatePanelShown }) => {
  const [currentEmail, updateCurrentEmail] = useState("");
  const [currentUsername, updateCurrentUser] = useState("");
  const [currentPass, updateCurrentPass] = useState("");
  const [currentPassVerify, updateCurrentPassVerify] = useState("");
  const [currentStatus, updateCurrentStatus] = useState("");
  const [showImport, updateShowImport] = useState("");
  const alert = useAlert()

  const register_user = async () => {
    if (!isEmail.validate(currentEmail))
      return alert.error("Invalid email")
    if (currentUsername.length < 4)
      return alert.error("Invalid username (min-length 4)")
    if (currentUsername !== encodeURIComponent(currentUsername))
      return alert.error("Invalid characters in username")
    if (currentPass.length < 8 || currentPassVerify.length < 8)
      return alert.error("Invalid password, must be length of 8 or greater.")
    if (currentPassVerify !== currentPass)
      return alert.error("Passwords do not match.")


    const response = await internal_fetch.register_user(
      currentUsername.trim(),
      currentEmail.trim(),
      currentPass
    );
    if (response && response.registerUser && response.registerUser.includes("Registered user")) {
      updateCurrentStatus(
        "Account created, please verify your account via the email sent to you."
      );
    }
    else if (response && response.errors === "Finish Import Process: Email") {
      updateCurrentStatus("Account with this email has been imported, Send email to finish import process?")
      updateCurrentEmail(currentEmail);
      updateShowImport(true);
    }
    else if (response && response.errors === "Finish Import Process: Username") {
      updateCurrentStatus("Account with this username has been imported, Send email to finish import process?")
      updateCurrentEmail(currentEmail);
      updateShowImport(true);
    }
    else if (response && response.errors)
      updateCurrentStatus(response.errors);

  };


  const sendImportEmail = async () => {
    const response = await internal_fetch.send_import_email(currentEmail);
    if (response && response.sendImportEmail)
      updateCurrentStatus(response.sendImportEmail);
    else if (response && response.errors)
      updateCurrentStatus(response.errors)
  };


  const checkEmail = async () => {
    if (!isEmail.validate(currentEmail))
      return updateCurrentStatus("Invalid email")
    const response = await internal_fetch.check_email(currentEmail.trim());
    if (response && response.checkEmail) {
      updateCurrentStatus(response.checkEmail);
    }
  }

  const checkUsername = async () => {
    if (currentUsername.length < 4)
      return updateCurrentStatus("Invalid username (min-length 4)")
    const response = await internal_fetch.check_username(currentUsername.trim());
    if (response && response.checkUsername) {
      updateCurrentStatus(response.checkUsername);
    }
  }

  return (
    <div className="create_account">
      <div className="login_form">
        <LoginFieldText
          placeholder="email" 
          currentValue={currentEmail}
          onChangeValue={updateCurrentEmail}
          onBlur={checkEmail}
          type="text" />

        <LoginFieldText
          placeholder="username"
          currentValue={currentUsername}
          onChangeValue={updateCurrentUser}
          onBlur={checkUsername}
          maxLength={15}
          type="text" />

        <LoginFieldText
          placeholder="password" 
          currentValue={currentPass}
          onChangeValue={updateCurrentPass}
          type="password" />


        <LoginFieldText
          placeholder="verify password" 
          currentValue={currentPassVerify}
          onChangeValue={updateCurrentPassVerify} 
          onKeyDown={(e) => e.key === "Enter" ? register_user() : null}
          type="password" />

        <FancyButton onClick={register_user} text="Create Account" />
        <div className="login_button_text" onClick={() => updatePanelShown(0)}>
          I already have an account
        </div>
      </div>
      {currentStatus ? <div className="status">{currentStatus}</div> : null}

      {showImport ? (
        <>
          <div className="resend_email_form">
            <input
              type="text"
              disabled
              className="nice_textbox"
              placeholder="email"
              value={currentEmail}
            />
            <FancyButton onClick={sendImportEmail} text="Send" />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default CreateAccountBox;
