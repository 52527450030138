// UserFavoritePostsPanel.js
import React, { useState, useEffect } from 'react';
import UsePagination from '../PostList/Paginator';
import logo_window from './../../assets/window_icon.svg'
import "react-tabs/style/react-tabs.css";
import "./InboxDashboard.css";
import "./../UserPublic/UserPublic.css";
import "react-tabs/style/react-tabs.css";
import InboxMessage from "./InboxMessage";


const InboxMessagesReadList = ({ userMessages, numberDisplayed, replyAction }) => {
    const [totalPages, updateTotalPages] = useState(0);
    const [selectedPage, updateSelectedPage] = useState(1);

    useEffect(() => {
        updateTotalPages(Math.ceil(userMessages.length / numberDisplayed))
    }, [userMessages]); // eslint-disable-line react-hooks/exhaustive-deps


    if (userMessages.length === 0) return <h1 className="nice_h1">No Read Messages found</h1>;

    return (
        <div className='user_panel'>
            <div className="comment_window_pop_up_title">
                <img className="logo_window" src={logo_window} alt="Logo Window" />
                <p className='title_comment_pop_up' style={{ textAlign: "start" }}>Read Messages</p>
            </div>
            <div className='user_public_body'>
                {totalPages === 0 || totalPages === 1 || userMessages.length <= 0 ? <></> : <UsePagination pages={totalPages} selectedPage={selectedPage} functions={{ updateSelectedPage }} ></UsePagination>}
                {userMessages.slice((selectedPage - 1) * numberDisplayed, selectedPage * numberDisplayed).map((message, i) => (
                    <InboxMessage message={message} reply={replyAction(message)} key={i} />
                ))}

                {totalPages === 0 || totalPages === 1 || userMessages.length <= 0 ? <></> : <UsePagination pages={totalPages} selectedPage={selectedPage} functions={{ updateSelectedPage }} ></UsePagination>}
            </div>
        </div>
    )
};

export default InboxMessagesReadList;
