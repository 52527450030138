import React from 'react';
import IconArrow from "@material-ui/icons/ArrowForwardIos"
import Spinner from '../Shared/Spinner';

const UserPublicSideNavContent = ({ children, isFetching, user, userId, username, currentTabIndex, privateMessagesEnabled, userIsBlocked, isLoggedIn, isAdmin, seePostsBy, seeCommentsBy, seeFavorites, updateModalShown, blockUser, purgeUser }) => {
    return (
        isFetching ? <Spinner loading={isFetching} /> : <>
            {(user.data && user.data.username !== username) && ((privateMessagesEnabled && !userIsBlocked) || isAdmin) ?
                <div className="control_text" onClick={() => updateModalShown(true)}><p>Send a message</p></div>
                : null}
            <div className={currentTabIndex === 0 ? "control_text_selected" : "control_text"} onClick={seePostsBy}><p>See posts by<IconArrow></IconArrow></p></div>
            <div className={currentTabIndex === 1 ? "control_text_selected" : "control_text"} onClick={seeCommentsBy}><p>See comments by<IconArrow></IconArrow></p></div>
            <div className={currentTabIndex === 2 ? "control_text_selected" : "control_text"} onClick={seeFavorites}><p>See favorites<IconArrow></IconArrow></p></div>
            {isLoggedIn ? <>
                {!isAdmin || (user.data && user.data.username === username) ? null : <hr />}
                {user.data && user.data.username === username ? null :
                    <div className={isAdmin ? "control_text_admin" : "control_text"} onClick={blockUser}>
                        <p>{userIsBlocked ? "Unblock User" : "Block User"}</p>
                    </div>}
                {isAdmin ? <div className="control_text_admin" onClick={() => purgeUser(userId)}>
                    <p>Purge user activity</p>
                </div> : null}
            </> : null}

            {children}
        </>
    );
};

export default UserPublicSideNavContent;