import React, { useState, useEffect } from 'react';
import Spinner from '../Shared/Spinner';
import Banner from "./Banner";
import PostControls from "./PostControls.js";

import ResolvedPosts from "./ResolvedPosts.js";
import UsePagination from './../PostList/Paginator.js';


const LatestResolved = ({ user, updateUser, windowState, functions: { updateLeftBar } }) => {

    const [posts, updatePosts] = useState([]);
    const [isFetching, updateIsFetching] = useState(false);
    const [totalPages, updateTotalPages] = useState(0);
    const [selectedPage, updateSelectedPage] = useState(0);

    //On Mount
    useEffect(() => { updateLeftBar(true) }, []); // eslint-disable-line react-hooks/exhaustive-deps
    //On Unmount
    useEffect(() => () => updateLeftBar(false), []); // eslint-disable-line react-hooks/exhaustive-deps

    const appendPosts = (newPosts) => {
        // const [...tmpPosts] = posts.concat(newPosts);
        const tmpPosts = newPosts;
        const uniqueArray = tmpPosts.filter((thing, index) => {
            const _thing = JSON.stringify(thing);
            return index === tmpPosts.findIndex(obj => {
                return JSON.stringify(obj) === _thing;
            });
        });
        updatePosts(uniqueArray);
    }

    return (
        <div className="post_list">
            <PostControls user={user} updateUser={updateUser} functions={{ updatePosts, updateIsFetching, updateTotalPages, appendPosts }} selectedPage={selectedPage} isFetching={isFetching} windowState={windowState} />
            <Banner />
            {
                <>
                    {totalPages === 0 || totalPages === 1 || posts.length <= 0 ? <></> : <UsePagination pages={totalPages} selectedPage={selectedPage} functions={{ updateSelectedPage }} ></UsePagination>}
                    {!isFetching ? <ResolvedPosts posts={posts} /> : <Spinner />}
                    {totalPages === 0 || totalPages === 1 || posts.length <= 0 ? <></> : <UsePagination pages={totalPages} selectedPage={selectedPage} functions={{ updateSelectedPage }} ></UsePagination>}
                </>
            }

        </div>
    );
}

export default LatestResolved;