import React from "react";
import SideNav from "../Nav/SideNav";
import IconArrow from "@material-ui/icons/ArrowForwardIos"
import { isClosedBeta } from "../../util/misc"

const AdminPanelControls = ({ user, updateUser, currentTabIndex, setTabIndex, }) => {

    const getClassFor = (index) => {
        return currentTabIndex === index ? "control_text_selected" : "control_text"
    }

    return <SideNav user={user} updateUser={updateUser}>
        {isClosedBeta() ? <div className={getClassFor(0)} onClick={() => setTabIndex(0)}><p>Register whitelist<IconArrow></IconArrow></p></div> : <></>}
        <div className={getClassFor(1)} onClick={() => setTabIndex(1)}><p>User controls<IconArrow></IconArrow></p></div>
        <div className={getClassFor(2)} onClick={() => setTabIndex(2)}><p>Username Settings<IconArrow></IconArrow></p></div>
        <div className={getClassFor(3)} onClick={() => setTabIndex(3)}><p>Statuses labels<IconArrow></IconArrow></p></div>
        <div className={getClassFor(4)} onClick={() => setTabIndex(4)}><p>Version numbers<IconArrow></IconArrow></p></div>
        <div className={getClassFor(5)} onClick={() => setTabIndex(5)}><p>Auto Tag<IconArrow></IconArrow></p></div>
        <div className={getClassFor(6)} onClick={() => setTabIndex(6)}><p>Flags<IconArrow></IconArrow></p></div>
        <div className={getClassFor(7)} onClick={() => setTabIndex(7)}><p>Audit Log<IconArrow></IconArrow></p></div>
        <div className={getClassFor(8)} onClick={() => setTabIndex(8)}><p>Info Boxes<IconArrow></IconArrow></p></div>
        <div className={getClassFor(9)} onClick={() => setTabIndex(9)}><p>Categories<IconArrow></IconArrow></p></div>
        <div className={getClassFor(10)} onClick={() => setTabIndex(10)}><p>Other Settings<IconArrow></IconArrow></p></div>
    </SideNav>;
}

export default AdminPanelControls;